import { Configuration, PopupRequest, BrowserCacheLocation, SilentRequest } from '@azure/msal-browser';

export enum ExternalId {
  AzimPremjiFoundation = 'azimpremjifoundation',
  Designit = 'designit',
  Wipro = 'wipro',
}

export class AuthConfig {
  private msalConfigAzimPremjiFoundation: Configuration = {
    auth: {
      clientId: 'e644f32b-3088-4e06-bd87-381bd834e7fd',
      redirectUri: 'https://apps.lab45.ai/azimpremjifoundation',
      authority: 'https://login.microsoftonline.com/349aae38-7afe-452a-b57e-45cebd0c7a50',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  };

  private msalConfigDesignit: Configuration = {
    auth: {
      clientId: 'de053e80-0088-424e-9ecb-f27a764e1427',
      redirectUri: 'https://apps.lab45.ai/designit',
      authority: 'https://login.microsoftonline.com/9a639b82-93c2-4b32-b2a9-39465da96123',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  };

  private msalConfig: Configuration = {
    auth: {
      clientId: 'a919164d-8b7c-43fb-8119-f1997d45ca4f',
      redirectUri: '/',
      authority: 'https://login.microsoftonline.com/258ac4e4-146a-411e-9dc8-79a9e12fd6da',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  };

  getMsalConfig(externalId: ExternalId = ExternalId.Wipro): Configuration {
    switch (externalId) {
      case ExternalId.Designit:
        return this.msalConfigDesignit;
      case ExternalId.AzimPremjiFoundation:
        return this.msalConfigAzimPremjiFoundation;
      default:
        return this.msalConfig;
    }
  }

  request_AzimPremjiFoundation: SilentRequest | PopupRequest = {
    scopes: ['e644f32b-3088-4e06-bd87-381bd834e7fd/.default'],
  };

  request_designit: SilentRequest | PopupRequest = {
    scopes: ['de053e80-0088-424e-9ecb-f27a764e1427/.default'],
  };

  msal_request: SilentRequest | PopupRequest = {
    scopes: ['a919164d-8b7c-43fb-8119-f1997d45ca4f/.default'],
  };

  getRequestConfig(externalId: ExternalId = ExternalId.Wipro): SilentRequest | PopupRequest {
    switch (externalId) {
      case ExternalId.Designit:
        return this.request_designit;
      case ExternalId.AzimPremjiFoundation:
        return this.request_AzimPremjiFoundation;
      default:
        return this.msal_request;
    }
  }
}
