import React from 'react';
import * as assets from '../../assets';
import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface ResponseContentProps {
  appMessage: string;
  modelIcon: string;
  modelTooltip?: string;
  isLoading: boolean;
}

export const components = {
  code({ node, inline, className, children, ...props }: any) {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <SyntaxHighlighter style={oneLight} language={match[1]} PreTag="div" {...props}>
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
};

const ResponseContent: React.FC<ResponseContentProps> = ({ appMessage, isLoading, modelIcon, modelTooltip }) => {
  return (
    <>
      <Box className="msgBox">
        <Box className="welcome-icon">
          <img id="changeicon" src={modelIcon} alt="" />
          {modelTooltip && (
            <Box className="toolTip">
              <strong>{modelTooltip}</strong>
            </Box>
          )}
        </Box>
        <Box className="chat-content" id="current-response-box" sx={{ '& p': { marginTop: 0 } }}>
          {isLoading ? (
            <img src={assets.loading} id="loadingimg" alt="" />
          ) : (
            <div>
              <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
                {appMessage}
              </ReactMarkdown>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResponseContent;
