import { Box, Drawer, useMediaQuery, Slide } from '@mui/material';
import React, { useContext } from 'react';

import { NavItemButton } from './navItemButton/NavItemButton';
import { WiproLogo } from './wiproLogo/WiproLogo';
import { SubPanel } from '../subpanel/SubPanel';
import { useTranslation } from 'react-i18next';
import UserAvatar from './userAvatar/UserAvatar';
import {
  chatNavigation,
  NAV_PANEL_WIDTH,
  NavItemImages,
  SectionDivider,
  welcomePageNavigation,
} from '../../staticComponents/StaticHtmlGenerator';
import Logout from '../subpanel/logout/Logout';
import { useNavigate } from 'react-router-dom';
import { ActiveNavItemContext } from '../RightPanelContext';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/redux-hooks';
import { saveInnovationCenterDetails } from '../../../reduxStore/slices/FeaturedSlice';

export function RightNavPanel({ userFullName, initials }: { userFullName: string; initials: string }) {
  const portrait = useMediaQuery('(orientation: portrait)');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeNavItem, setActiveNavItem } = useContext(ActiveNavItemContext);
  const { innovationCenter } = useAppSelector((state) => state.featured);
  const dispatch = useAppDispatch();

  // Configuration for the nav items
  const navItemsConfig = [
    { name: t('welcomepage.Home'), imageUrl: NavItemImages.home, visible: true },
    { name: t('welcomepage.Chat'), imageUrl: NavItemImages.chat, visible: true },
    { name: t('welcomepage.Dataset'), imageUrl: NavItemImages.dataset, visible: true },
    { name: t('welcomepage.Agent'), imageUrl: NavItemImages.agents, visible: true },
    { name: t('welcomepage.Teams'), imageUrl: NavItemImages.teams, visible: false },
    { name: t('welcomepage.Apps'), imageUrl: NavItemImages.apps, visible: true },
    { name: t('welcomepage.History'), imageUrl: NavItemImages.history, visible: true },
    { name: t('welcomepage.Bell'), imageUrl: NavItemImages.bell, visible: false, badge: true },
  ];

  const clickOnNavButton = (name: string) => {
    setActiveNavItem(name);
    if (name === t('welcomepage.Home')) {
      navigate(welcomePageNavigation);
      if (innovationCenter.isActive) {
        dispatch(saveInnovationCenterDetails({ isActive: false }));
      }
    }
    if (name === t('welcomepage.Chat')) {
      navigate(chatNavigation);
    }
  };

  const loadNavItems = () => {
    return (
      <>
        {navItemsConfig.map(({ name, imageUrl, visible, badge }, index) => {
          if (visible) {
            return (
              <Box key={index}>
                {name === t('welcomepage.History') && <SectionDivider />}
                <NavItemButton
                  key={index}
                  name={name}
                  imageUrl={imageUrl}
                  badge={badge}
                  isActive={activeNavItem === name}
                  clickOnNavButton={clickOnNavButton}
                />
              </Box>
            );
          }
          return null;
        })}
      </>
    );
  };

  return (
    <Box>
      <WiproLogo />
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <Drawer
          id={'right-nav-panel'}
          PaperProps={{
            sx: {
              backgroundColor: '#FBFAFA',
              border: '1px solid #F2F2F2',
              width: NAV_PANEL_WIDTH,
              height: '100%',
              marginTop: {
                xs: portrait ? '64px' : '0',
                md: '0',
              },
            },
          }}
          anchor={'right'}
          variant={'permanent'}
          open={true}
        >
          <Box
            sx={{
              padding: '30px 0 30px 0',
            }}
          >
            <UserAvatar initials={initials} />
            <SectionDivider />
            {loadNavItems()}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'flex-end',
              marginBottom: {
                xs: portrait ? '64px' : '0',
                md: '0',
              },
            }}
          >
            <Logout />
          </Box>
        </Drawer>
      </Slide>
      {activeNavItem !== t('welcomepage.Home') && activeNavItem !== t('welcomepage.Chat') && (
        <SubPanel userFullName={userFullName} />
      )}
    </Box>
  );
}
