import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import { Box, Button } from '@mui/material';
import { selectedDataSet } from '../../reduxStore/slices/DataSetSlice';
import { useTranslation } from 'react-i18next';
import {
  updateFileSelectionState,
  setSuccessResponseModal,
  updateDatasetState,
} from '../../reduxStore/slices/fileUploadSlice';
import { showUserActionContentOnChat } from '../../reduxStore/slices/NotifyUserActionContentSlice';
import { activeAgent, agentActivationProcess } from '../../reduxStore/slices/AgentsSlice';
import { useContext } from 'react';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';

// This component renders a dialog box that asks the user to confirm whether they want to activate a dataset or an agent.
const DialogBox = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { currentDataSetId, currentDataSetName } = useAppSelector((state) => state.dataset.currentDataSet);
  const { ShowAgentActivationDialogOnChat, activationAgent } = useAppSelector(
    (state) => state.agents.agentActivationProcess
  );
  const { makeActiveDataset } = useAppSelector((state) => state.fileUpload.dataset);
  const { setActiveNavItem } = useContext(ActiveNavItemContext);

  const handleYesClick = () => {
    // If the user wants to activate a dataset.
    if (makeActiveDataset) {
      handleDatasetActivationOnYes();
    }
    // If the user wants to activate an agent.
    if (ShowAgentActivationDialogOnChat) {
      handleAgentActivationOnYes();
    }
  };

  const handleNoClick = () => {
    // If the user wants to activate a dataset.
    if (makeActiveDataset) {
      handleDatasetActivationOnNo();
    }
    // If the user wants to activate an agent.
    if (ShowAgentActivationDialogOnChat) {
      handleAgentActivationOnNo();
    }
  };

  const handleAgentActivationOnYes = () => {
    // Dispatch an action to update the agent activation process state.
    dispatch(
      agentActivationProcess({
        // Set ShowAgentActivationDialogOnChat to false to close the dialog box.
        ShowAgentActivationDialogOnChat: false,
        // Reset the activationAgent state.
        activationAgent: {
          activationAgentName: '',
          activationAgentId: '',
          activationAgentModelName: '',
        },
      })
    );

    // Dispatch an action to activate the agent.
    dispatch(
      activeAgent({
        activeAgentName: activationAgent.activationAgentName,
        activeAgentId: activationAgent.activationAgentId,
        activeAgentModelName: activationAgent.activationAgentModelName,
      })
    );

    // Dispatch an action to show a user action content message on the chat.
    dispatch(
      // Show a message that the agent has been activated.
      showUserActionContentOnChat(
        t('rightPanel.agent.activateAgent', { activeAgentName: activationAgent.activationAgentName })
      )
    );
  };

  const handleAgentActivationOnNo = () => {
    // Dispatch an action to update the agent activation process state.
    dispatch(
      agentActivationProcess({
        // Set ShowAgentActivationDialogOnChat to false to close the dialog box.
        ShowAgentActivationDialogOnChat: false,
        // Reset the activationAgent state.
        activationAgent: {
          activationAgentName: '',
          activationAgentId: '',
          activationAgentModelName: '',
        },
      })
    );
  };

  const handleDatasetActivationOnYes = () => {
    dispatch(
      updateDatasetState({
        makeActiveDataset: false,
      })
    );
    dispatch(setSuccessResponseModal(false));
    dispatch(
      selectedDataSet({
        activeDataSetId: currentDataSetId,
        activeDataSetName: currentDataSetName,
      })
    );
    dispatch(showUserActionContentOnChat(t('fileUpload.activeMessage', { fileName: currentDataSetName })));
    setActiveNavItem(t('welcomepage.Dataset'));
  };

  const handleDatasetActivationOnNo = () => {
    dispatch(setSuccessResponseModal(true));
    dispatch(
      updateDatasetState({
        makeActiveDataset: false,
      })
    );
    dispatch(updateFileSelectionState({ isDatasetActive: false }));
  };

  // This function returns the content of the dialog box.
  const getActivationDialogContentOnChat = () => {
    return makeActiveDataset ? (
      // If the user wants to activate a dataset, return a message asking if they want to activate the dataset.
      <>
        <strong>{currentDataSetName}</strong> {t('fileUpload.activateDataset')}
      </>
    ) : ShowAgentActivationDialogOnChat ? (
      // If the user wants to activate an agent, return a message asking if they want to activate the agent.
      <>
        <strong>&quot;{activationAgent.activationAgentName}&quot;</strong>{' '}
        {t('rightPanel.agent.createAgent.createAgentSuccess')}
      </>
    ) : null;
  };

  return (
    <Box marginLeft={'3.5rem'}>
      <span>{getActivationDialogContentOnChat()}</span>
      <Box padding={'15px 0px'}>
        <Button
          variant="contained"
          onClick={handleNoClick}
          sx={{
            marginRight: '1rem',
            bgcolor: '#AFB0B4',
            ':hover': {
              bgcolor: '#AFB0B4',
              color: 'white',
            },
          }}
        >
          {t('fileUpload.no')}
        </Button>

        <Button
          variant="contained"
          onClick={handleYesClick}
          sx={{
            bgcolor: '#256AFF',
            ':hover': {
              bgcolor: 'blue',
              color: 'white',
            },
          }}
        >
          {t('fileUpload.yes')}
        </Button>
      </Box>
    </Box>
  );
};

export default DialogBox;
