import React from 'react';
import { Box } from '@mui/material';

interface UserContentProps {
  initials: string;
  message: string;
  imageSelectionChange: () => string;
  toolTipTextChange: (value: string) => string;
}

const UserContent: React.FC<UserContentProps> = ({ initials, message }) => {
  return (
    <>
      <Box className="msgBox chat-content">
        <Box>
          <Box className="welcome-icon profile-icon">{initials}</Box>
        </Box>
        <p className="usrMsg">{message}</p>
      </Box>
    </>
  );
};

export default UserContent;
