import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DeleteModalProps {
  openDeleteModal: boolean;
  closeDeleteModal: () => void;
  modalAPi: () => void;
  dialogTitle: string;
  textContent: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  openDeleteModal,
  closeDeleteModal,
  modalAPi,
  dialogTitle,
  textContent,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      sx={{ borderRadius: '4px' }}
      open={openDeleteModal}
      onClose={closeDeleteModal}
      aria-labelledby="responsive-dialog-title"
    >
      <Box padding={'20px 40px'}>
        <DialogTitle id="responsive-dialog-title" textAlign={'center'}>
          <Typography fontSize={24} fontWeight={700}>
            {dialogTitle}
          </Typography>
          <Divider sx={{ padding: '10px' }} />
        </DialogTitle>

        <DialogContent>
          <DialogContentText textAlign={'center'} color={'#505050'}>
            {textContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: 'none', textDecoration: 'underline' }}
            autoFocus
            size="small"
            onClick={closeDeleteModal}
          >
            {t('rightPanel.cancel')}
          </Button>
          <Button
            sx={{ textTransform: 'none', backgroundColor: '#B91C1B' }}
            variant="contained"
            color="error"
            size="small"
            onClick={() => modalAPi()}
            autoFocus
          >
            {t('rightPanel.deleteButton')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteModal;
