import { Box, Divider, SelectChangeEvent, Tooltip, tooltipClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FileUpload from './FileUpload';
import ModelSelect from './ModelSelect';
import ImageButton from '../../components/sharedComponents/ImageButton';
import { LlmModel } from '../../data/models/LlmModel';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import * as assets from '../../assets';
import SendChat from './SendChat';
import { useAppSelector } from '../../reduxStore/redux-hooks';

interface QueryInputActionsProps {
  disabled: boolean;
  maxLength: number;
  currentMessage: string;
  selectedModel: LlmModel;
  llmModels: LlmModel[];
  resetsTranscript: boolean;
  handleModelChange: (event: SelectChangeEvent) => void;
  setCurrentMessage: (transcript: string) => void;
  __ask: () => Promise<void>;
  setResetsTranscript: (value: boolean) => void;
}

const QueryInputActions: React.FC<QueryInputActionsProps> = ({
  disabled,
  maxLength,
  currentMessage,
  selectedModel,
  llmModels,
  resetsTranscript,
  handleModelChange,
  setCurrentMessage,
  __ask,
  setResetsTranscript,
}) => {
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [micEnabled, setMicEnabled] = useState(false);
  const [resetTranscripts, setResetTranscripts] = useState(false);
  const [usableTranscript, setUsableTranscript] = useState('');
  const { innovationCenter } = useAppSelector((state) => state.featured);

  useEffect(() => {
    if (transcript.length && resetTranscripts) {
      setResetsTranscript(false);
      setResetTranscripts(false);
      checkInputUpdated();
      setCurrentMessage(currentMessage + (transcript.length ? (currentMessage.length ? ' ' : '') + transcript : ''));
    }
    if (transcript.length && !resetTranscripts) {
      // usual case when the input is not chnaged by the user
      const str = usableTranscript + transcript;
      setCurrentMessage(str);
    }
  }, [transcript]);

  useEffect(() => {
    if (resetsTranscript) {
      resetTranscript();
      setResetTranscripts(true);
      setUsableTranscript(currentMessage);
    }
  }, [resetsTranscript]);

  const checkInputUpdated = () => {
    if (currentMessage !== usableTranscript) {
      setUsableTranscript(currentMessage);
    }
  };

  const startStopListening = () => {
    if (!micEnabled) {
      setMicEnabled(true);
      SpeechRecognition.startListening({ continuous: true });
    } else {
      setMicEnabled(false);
      stopVoiceInput();
      resetTranscript();
      setResetTranscripts(false);
    }
  };

  const stopVoiceInput = () => {
    if (currentMessage && currentMessage.length) {
      setCurrentMessage(transcript);
    } else {
      setCurrentMessage(transcript.length ? transcript : '');
    }
    SpeechRecognition.stopListening();
  };

  const queryInputActionsTooltipStyles = {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#000000BA',
      },
      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
        '&::before': {
          backgroundColor: '#000000BA',
        },
      },
    },
  };

  return (
    <Box className="query-input-actions">
      {!disabled && (
        <Box>
          <span data-testid="character-count">
            {/* show word count only when the input length lies within 10% of maxLimit(65536) */}
            {currentMessage.length >= maxLength * 0.9 ? `${currentMessage.length} / ${maxLength}` : ''}
          </span>
        </Box>
      )}

      <Box>
        <ModelSelect handleModelChange={handleModelChange} selectedModel={selectedModel} llmModels={llmModels} />
      </Box>

      <Tooltip
        title="Upload file"
        enterDelay={1000}
        arrow
        placement="top"
        slotProps={{
          popper: queryInputActionsTooltipStyles,
        }}
      >
        <Box className="query-input-action-item" sx={{ opacity: innovationCenter.isActive ? '0.5' : '1' }}>
          <FileUpload disabled={!disabled || innovationCenter.isActive} />
        </Box>
      </Tooltip>

      <Tooltip
        title="Speak"
        enterDelay={1000}
        arrow
        placement="top"
        slotProps={{
          popper: queryInputActionsTooltipStyles,
        }}
      >
        <Box
          className={
            transcript
              ? micEnabled
                ? 'beat query-input-action-item'
                : 'query-input-action-item'
              : 'query-input-action-item'
          }
        >
          <Box
            sx={{
              position: 'absolute',
              left: micEnabled ? (transcript ? '-3px' : '-3px') : '8px',
            }}
          >
            <ImageButton
              srcFile={micEnabled ? (transcript ? assets.speakerListening : assets.speakerActive) : assets.speaker}
              className="speak"
              handleClick={() => {
                startStopListening();
              }}
            />
          </Box>
        </Box>
      </Tooltip>

      <Divider
        sx={{ marginRight: '-5px', marginLeft: '-5px', borderLeft: '1px solid #DBDBDB' }}
        orientation="vertical"
        flexItem
      />
      <Tooltip
        title="Send"
        enterDelay={1000}
        arrow
        placement="top"
        slotProps={{
          popper: queryInputActionsTooltipStyles,
        }}
      >
        <Box className="query-input-action-item">
          <SendChat
            disabled={disabled || (innovationCenter.isActive && !innovationCenter.selectedDatasetIdForInnovationCenter)}
            onSendChatPressed={__ask}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default QueryInputActions;
