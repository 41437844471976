import { updateDatasetState } from '../../../../reduxStore/slices/fileUploadSlice';
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/redux-hooks';
import { Box, Input, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NewDataset: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { dataset, fileUpload } = useAppSelector((state) => state.fileUpload);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const datasetName = event.target.value;
    dispatch(
      updateDatasetState({
        datasetName: datasetName,
      })
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.75rem',
      }}
    >
      <Box>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{t('fileUpload.enterName')}</Typography>
        <Input
          fullWidth
          sx={{ padding: '0.2rem 0.75rem' }}
          id="dataset-name"
          defaultValue={dataset.datasetName}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ color: '#636363' }}>{t('fileUpload.datasetContent')}</Typography>
        <Typography>{fileUpload.uploadedFiles.length}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '6.5rem',
          overflowY: 'scroll',
          gap: '.25rem',
        }}
      >
        {fileUpload.uploadedFiles.map((file, index) => (
          <Typography
            key={index}
            sx={{
              padding: '.25rem .25rem',
              background: '#F6F6F6B2',
              borderRadius: '.25rem',
            }}
          >
            {file.name}
          </Typography>
        ))}
      </Box>
      <Box>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{t('fileUpload.description')}</Typography>
        <Input fullWidth sx={{ padding: '0.2rem 0.75rem' }} id="standard-basic" />
      </Box>
    </Box>
  );
};

export default NewDataset;
