import {
  Avatar,
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as assets from '../../assets';

const serviceNowLink =
  'https://wiprocio.service-now.com/sp?id=sc_cat_item&sys_id=0b5d34bfdbc160501d907c63f3961951&sysparm_function=08d86756db7b9c101d907c63f396197a&sysparm_subfunction=840b316e0b1bddd4060e8fcfd5f8df23&sysparm_type=other';

export const NoAccessToInnovationCenterHTML = () => {
  return (
    <Box className="msgBox">
      <Box className="welcome-icon">
        <img id="changeicon" src={assets.chatGpt_logo} alt="chat gpt logo" />
        <Box className="toolTip">
          <strong>3.5</strong>
        </Box>
      </Box>
      <Box className="chat-content" sx={{ '& p': { marginTop: 0 } }}>
        <Typography marginBottom={'0.5rem'}>
          Can&apos;t see this page? Let&apos;s get you access,{' '}
          <Link href={serviceNowLink} underline="hover" target="_blank">
            submit
          </Link>{' '}
          a ticket to view this content.
        </Typography>
        <Typography>Please choose:</Typography>
        <List sx={{ listStyleType: 'disc', padding: '0px', paddingLeft: '15px' }}>
          <ListItem sx={{ display: 'list-item', padding: '0px' }}>
            <ListItemText
              primary={
                <span>
                  <Typography component="span" sx={{ fontWeight: '700' }}>
                    Service Category:
                  </Typography>{' '}
                  ai360 platform
                </span>
              }
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: '0px' }}>
            <ListItemText
              primary={
                <span>
                  <Typography component="span" sx={{ fontWeight: '700' }}>
                    Classification:
                  </Typography>{' '}
                  Access request
                </span>
              }
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

// Mapping nav item name to icon
export const NavItemImages: { [key: string]: string } = {
  bell: assets.bell,
  chat: assets.chatMenu,
  dataset: assets.datasets,
  agents: assets.agents,
  teams: assets.teams,
  history: assets.history,
  home: assets.home,
  setting: assets.setting,
  apps: assets.customApps,
};

export const NAV_PANEL_WIDTH = 65;
export const SUB_PANEL_WIDTH = 352;
export const SUB_PANEL_WIDTH_SX = 287;

export const chatNavigation = '/Chat';
export const welcomePageNavigation = '/WelcomePage';

//Section divider
export function SectionDivider(props: { sx?: { [key: string]: string | number } }) {
  return (
    <Divider
      variant={'middle'}
      sx={{ borderStyle: 'dashed', borderColor: '#B3B9C4', height: '30px', ...props.sx }}
      aria-roledescription={'separator'}
    />
  );
}

/**
 * Check if the screen is portrait.
 */
export function usePortrait(): boolean {
  return useMediaQuery('(orientation: portrait)');
}

/**
 * Check if the main input can hover.
 */
export function useCanHover(): boolean {
  return !useMediaQuery('(hover: none)');
}

export function createMatchMedia(expectedQuery: string, matches: boolean): (query: string) => MediaQueryList {
  return (query) => {
    return {
      media: '',
      matches: query === expectedQuery && matches,
      onchange: () => {},
      dispatchEvent: () => {
        return false;
      },
      addListener: () => {},
      removeListener: () => {},
      addEventListener: () => {},
      removeEventListener: () => {},
    };
  };
}

//this if will be completely removed once we starting on implemeting custom apps
//in an organised way.
export const ActiveInnovationCenter = () => {
  return (
    <Box sx={{ padding: '10px' }}>
      <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
        Active
      </Typography>
      <List>
        <ListItemButton
          sx={{
            padding: '5px',
            paddingLeft: '0px',
            width: '100%',
          }}
        >
          <ListItemAvatar sx={{ alignSelf: 'start', minWidth: '50px' }}>
            <Avatar className="avatar" alt="default agent" src={assets.ai360Agent} />
          </ListItemAvatar>
          <Box width={'100%'}>
            <Typography color={'#0B0C0C'} fontWeight={700} fontSize={'16px'}>
              Innovation Center
            </Typography>
          </Box>
        </ListItemButton>
      </List>
    </Box>
  );
};

export const featuredList = [
  {
    featureId: 'developer',
    featureName: 'Developer',
    featureBackgroundColor: '#EDF4FF',
    featureDisable: false,
    featureIcon: `${assets.codeWrite}`,
  },
  {
    featureId: 'innovation',
    featureName: 'Innovation Centers',
    featureBackgroundColor: '#F3FFF9',
    featureDisable: false,
    featureIcon: `${assets.brainstrom}`,
  },
  {
    featureId: 'hr',
    featureName: 'HR',
    featureBackgroundColor: '#FFF8F4',
    featureDisable: true,
    featureIcon: `${assets.summary}`,
  },
  {
    featureId: 'marketing',
    featureName: 'Marketing',
    featureBackgroundColor: '#FFF3FC',
    featureDisable: true,
    featureIcon: `${assets.analyseData}`,
  },
  {
    featureId: 'sales',
    featureName: 'Sales',
    featureBackgroundColor: '#ECF9FF',
    featureDisable: true,
    featureIcon: `${assets.brainstrom}`,
  },
  {
    featureId: 'finance',
    featureName: 'Finance',
    featureBackgroundColor: '#FDF7FF',
    featureDisable: true,
    featureIcon: `${assets.summary}`,
  },
];

export const subFeaturedList = [
  {
    associatedFeatureId: 'hr',
    associatedSubFeatures: [],
  },
  {
    associatedFeatureId: 'sales',
    associatedSubFeatures: [],
  },
  {
    associatedFeatureId: 'marketing',
    associatedSubFeatures: [],
  },
  {
    associatedFeatureId: 'finance',
    associatedSubFeatures: [],
  },
  {
    associatedFeatureId: 'developer',
    associatedSubFeatures: [
      {
        name: 'Code Generator',
        description: 'Write SQL from natural Language',
        icon: `${assets.recentChat}`,
        enabled: false,
      },
      {
        name: 'Code Debug',
        description: 'Create Python function code from Natural Language',
        icon: `${assets.recentChat}`,
        enabled: false,
      },
      {
        name: 'Unit Test Creator',
        description: 'Optimise query SQL for PostgreSQL and MSSQL',
        icon: `${assets.recentChat}`,
        enabled: false,
      },
      {
        name: 'CI/CD Pipelines',
        description: 'Create your own complete end to end pipeline delivery',
        icon: `${assets.recentChat}`,
        enabled: false,
      },
      {
        name: 'Create Your Own',
        description: 'Create your own custom Agent',
        icon: `${assets.recentChat}`,
        enabled: false,
      },
    ],
  },
  {
    associatedFeatureId: 'innovation',
    associatedSubFeatures: [
      {
        name: 'Wipro Lab45',
        description: 'Lorem Ipsum text',
        icon: `${assets.recentChat}`,
        enabled: true,
      },
      {
        name: 'Wipro Patents (US)',
        description: 'Lorem Ipsum text',
        icon: `${assets.recentChat}`,
        enabled: true,
      },
      {
        name: 'AI360 aka Lab45 AI Platform',
        description: 'Lorem Ipsum text',
        icon: `${assets.recentChat}`,
        enabled: true,
      },
      {
        name: 'Metaverse',
        description: 'Lorem Ipsum text',
        icon: `${assets.recentChat}`,
        enabled: true,
      },
      {
        name: 'Oil Refinery',
        description: 'Lorem Ipsum text',
        icon: `${assets.recentChat}`,
        enabled: true,
      },
    ],
  },
];
