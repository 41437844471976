import { Box, CircularProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import { ConversationData } from '../History';
import { useTranslation } from 'react-i18next';
import BasicPopover from '../../../../sharedComponents/BasicPopover';
import ImageButton from '../../../../sharedComponents/ImageButton';
import * as assets from '../../../../../assets';
import HistoryPopoverData from '../editHistory/EditHistory';
import { getFormattedDateAndTime } from '../../../../staticComponents/staticUtiles';
import { buttonStyle } from '../../dataset/Dataset';

interface ChatHistoryListProps {
  enableLoader: boolean;
  historyData: ConversationData[];
  fetchAndProcessConversationMessages: (conversationId: string, conversationHeading: string) => void;
}
const ChatHistoryList = (props: ChatHistoryListProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <List className="dataList_ul" sx={{ overflowY: 'auto', maxHeight: '15.5rem', padding: 0 }}>
        {props.enableLoader ? (
          <CircularProgress size={20} sx={{ marginTop: '10px' }} />
        ) : props.historyData && props.historyData.length > 0 ? (
          props.historyData.map((listItem) => {
            const { dateString, timeString } = getFormattedDateAndTime(listItem.last_updated);

            return (
              <Box key={listItem.conversation_id}>
                <Box>
                  <ListItem
                    sx={{
                      color: '#256AFF',
                      transition: 'all 300ms ease-in-out',
                      padding: 0,
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>
                      <ListItemText
                        sx={{ textDecoration: 'underline' }}
                        onClick={() =>
                          props.fetchAndProcessConversationMessages(listItem.conversation_id, listItem.title)
                        }
                        primary={listItem.title}
                      />
                      <Box style={{ display: 'flex', justifyContent: 'space-between', color: '#7E7E7E' }}>
                        <span style={{ width: '92px', fontSize: 'small', marginBottom: '1rem' }}>{dateString}</span>
                        <span style={{ width: '92px', fontSize: 'small' }}>{timeString}</span>
                      </Box>
                    </Box>
                    <BasicPopover
                      srcComponent={
                        <ImageButton
                          className="buttonShowOnHover"
                          srcFile={assets.dots}
                          style={{
                            ...buttonStyle,
                          }}
                        />
                      }
                      contentComponent={
                        <HistoryPopoverData
                          conversationHeading={listItem.title}
                          conversationID={listItem.conversation_id}
                        />
                      }
                    />
                  </ListItem>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography>{t('history.noHistoryToShow')}</Typography>
        )}
      </List>
    </Box>
  );
};

export default ChatHistoryList;
