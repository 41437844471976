import React from 'react';
import { LlmModel } from '../../data/models/LlmModel';
import { SelectChangeEvent } from '@mui/material';
import { InputLabel, Select, MenuItem } from '@mui/material';
import { ReactComponent as arrowDownIcon } from '../../assets/arrowDown.svg';
import { useAppSelector } from '../../reduxStore/redux-hooks';

interface ModelSelectProps {
  handleModelChange: (event: SelectChangeEvent) => void;
  selectedModel: LlmModel;
  llmModels: LlmModel[];
}

const ModelSelect: React.FC<ModelSelectProps> = ({ handleModelChange, selectedModel, llmModels }) => {
  const { innovationCenter } = useAppSelector((state) => state.featured);

  return (
    <div className="model-box d-flex">
      <Select
        sx={{
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D0D4',
            borderWidth: '1px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            top: '0px',
          },
          '&.MuiOutlinedInput-root': {
            legend: {
              display: 'none',
            },
          },
          '& .MuiSelect-icon': {
            right: '10px',
            top: 'calc(50% - .25em)',
          },
          height: '1.8rem',
          paddingRight: '0.75rem',
        }}
        labelId="demo-simple-select-label"
        data-testid="demo-simple-select"
        id="demo-simple-select"
        onChange={handleModelChange}
        value={selectedModel.id}
        label={<InputLabel shrink={false} />}
        IconComponent={arrowDownIcon}
        disabled={innovationCenter.isActive}
      >
        {llmModels.map((model) => (
          <MenuItem className="llm-models-list" key={model.id} value={model.id}>
            <img src={model.image} width="20px" height="20px" alt={model.id} />
            {model.displayName}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ModelSelect;
