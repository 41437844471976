import { Box } from '@mui/material';
import Auth from '../../../../helpers/Auth';
import React from 'react';
import ImageButton from '../../../sharedComponents/ImageButton';
import * as assets from '../../../../assets';

function Logout() {
  const handleLogoutClick = () => {
    Auth.then(async (Auth) => await Auth.logout());
  };

  return (
    <Box
      id={'logout'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 65,
        position: 'relative',
        bottom: 1,
      }}
    >
      <Box
        sx={{
          height: 40,
          width: 40,
          backgroundColor: '#FFF3F3',
          borderRadius: '7px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': { cursor: 'pointer' },
        }}
      >
        <ImageButton srcFile={assets.logout} handleClick={handleLogoutClick} />
      </Box>
    </Box>
  );
}

export default Logout;
