import { configureStore } from '@reduxjs/toolkit';
import dataSetReducer from './slices/DataSetSlice';
import fileUploadReducer from '../reduxStore/slices/fileUploadSlice';
import agentsReducer from '../reduxStore/slices/AgentsSlice';
import historyReducer from '../reduxStore/slices/historySlice';
import notifyUserActionContentReducer from './slices/NotifyUserActionContentSlice';
import featuredReducer from './slices/FeaturedSlice';

export const store = configureStore({
  reducer: {
    dataset: dataSetReducer,
    fileUpload: fileUploadReducer,
    agents: agentsReducer,
    history: historyReducer,
    notifyUserActionContent: notifyUserActionContentReducer,
    featured: featuredReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
