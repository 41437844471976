import { Avatar, Box, List, ListItemButton, ListItemAvatar, Typography } from '@mui/material';
import React from 'react';
import * as assets from '../../../../assets';
import { useTranslation } from 'react-i18next';
import BasicPopover from '../../../sharedComponents/BasicPopover';
import ImageButton from '../../../sharedComponents/ImageButton';
import EditAgent from './editAgent/EditAgent';
import { useAppSelector } from '../../../../reduxStore/redux-hooks';
import MyAgents, { MyAgentsData } from './myAgents/MyAgents';
import { SectionDivider } from '../../../staticComponents/StaticHtmlGenerator';
import { buttonStyle } from '../dataset/Dataset';

function Agents() {
  const { t } = useTranslation();
  const { activeAgentName, activeAgentId, activeAgentModelName } = useAppSelector((state) => state.agents.activeAgent);

  const renderEditActiveAgentPopover = (agent: MyAgentsData) => {
    return (
      <BasicPopover
        srcComponent={
          <Box className="hover-showDotsButton">
            <ImageButton srcFile={assets.dots} style={buttonStyle} />
          </Box>
        }
        contentComponent={<EditAgent agent={agent} isActiveAgent={true} />}
      />
    );
  };

  return (
    <Box id={'agent-2nd-level-content'}>
      <Box className="active-agent" marginBottom={'25px'}>
        <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
          {t('rightPanel.agent.activeAgents')}
        </Typography>

        <Box>
          {!activeAgentName ? (
            <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
              {t('rightPanel.agent.noActiveAgents')}
            </Typography>
          ) : (
            <List>
              <ListItemButton
                key={activeAgentId}
                className="agents-list"
                sx={{
                  padding: '5px',
                  paddingLeft: '0px',
                  width: '100%',
                }}
              >
                <ListItemAvatar sx={{ alignSelf: 'start', minWidth: '50px' }}>
                  <Avatar className="avatar" alt="default agent" src={assets.ai360Agent} />
                </ListItemAvatar>
                <Box width={'100%'}>
                  <Typography color={'#0B0C0C'} fontWeight={700} fontSize={'16px'}>
                    {activeAgentName}
                  </Typography>
                  <Typography color={'#50595F'} fontWeight={400} fontSize={'14px'}>
                    {t('llmModels.gpt-35-turbo-16k')}
                  </Typography>
                  <Typography sx={{ textDecoration: 'underline' }} color={'#256AFF'} fontWeight={700} fontSize={'14px'}>
                    {t('rightPanel.configure')}
                  </Typography>
                </Box>
                <span className="buttonShowOnHover">
                  {renderEditActiveAgentPopover({
                    name: activeAgentName,
                    _id: activeAgentId,
                    model_name: activeAgentModelName,
                  })}
                </span>
              </ListItemButton>
            </List>
          )}
        </Box>
      </Box>

      <Box className="my-agents" marginBottom={'25px'}>
        <MyAgents />
      </Box>

      <SectionDivider sx={{ marginTop: '40px', marginLeft: '0px', marginRight: '0px' }} />
    </Box>
  );
}

export default Agents;
