import React, { useCallback, useEffect, useState } from 'react';
import * as assets from '../../../../assets';
import { Box, Button, CircularProgress, List, ListItem, ListItemText, SvgIcon, Typography } from '@mui/material';
import DeleteModal from '../../../sharedComponents/DeleteModal';
import { useTranslation } from 'react-i18next';
import Api from '../../../../data/api/Api';
import { useAppDispatch } from '../../../../reduxStore/redux-hooks';
import { currentDataSet, selectedDataSet, sharedDataSet } from '../../../../reduxStore/slices/DataSetSlice';
import { AccountCircle, BoyOutlined } from '@mui/icons-material';
import ImageButton from '../../../sharedComponents/ImageButton';
import User from '../../../../helpers/User';
import { datasetSharedUser } from './DatasetList';
import { SectionDivider } from '../../../staticComponents/StaticHtmlGenerator';

interface EditDatasetProps {
  activeDataSetName: string;
  activeDataSetId: string;
  isFromDatasetList: boolean;
  currentDatasetId: string;
  currentDatasetName: string;
  isItemVisible: boolean;
  ownerListDataset: string[];
  sharedListDataset: datasetSharedUser[];
}
interface DataSetToBeDeleted {
  datasetId: string | null;
  datasetName: string | null;
  isSharedDataset: boolean;
}

const EditDataset: React.FC<EditDatasetProps> = ({
  activeDataSetName,
  activeDataSetId,
  isFromDatasetList,
  currentDatasetId,
  currentDatasetName,
  isItemVisible,
  ownerListDataset = [],
  sharedListDataset = [],
}) => {
  const user = User.empty;
  const [isDeleteDatasetModalOpen, setIsDeleteDatasetModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [dataSetToBeDeleted, setDataSetToBeDeleted] = useState<DataSetToBeDeleted>({
    datasetId: null,
    datasetName: null,
    isSharedDataset: false,
  });
  const { t } = useTranslation();
  const datasetNameToShow = isFromDatasetList ? currentDatasetName : activeDataSetName;
  const datasetIdToShow = isFromDatasetList ? currentDatasetId : activeDataSetId;
  const modifiedTextContent = dataSetToBeDeleted.isSharedDataset
    ? `${t('rightPanel.confirmDelete')} ${dataSetToBeDeleted.datasetName}?`
    : `${t('rightPanel.confirmDelete')} ${datasetNameToShow}?`;
  const deleteTitleName = dataSetToBeDeleted.isSharedDataset
    ? `${t('rightPanel.deleteShareUser')}`
    : `${t('rightPanel.deleteDataset')}`;
  const dispatch = useAppDispatch();

  const clearSelectedDataSet = () => {
    dispatch(
      selectedDataSet({
        activeDataSetName: '',
        activeDataSetId: '',
      })
    );
  };

  const clearCurrentDataset = () => {
    dispatch(
      currentDataSet({
        currentDataSetName: '',
        currentDataSetId: '',
      })
    );
  };

  const clearSharedDataset = () => {
    dispatch(sharedDataSet({ sharedDataList: {} }));
  };

  const handleUnload = () => {
    isFromDatasetList ? clearCurrentDataset() : clearSelectedDataSet();
  };

  const openDeleteModal = (dataset: DataSetToBeDeleted) => {
    setIsDeleteDatasetModalOpen(true);
    setDataSetToBeDeleted(dataset);
  };

  const deleteDatasetSharedUser = useCallback(async () => {
    setIsDeleting(true);
    try {
      const deleteSharedUserResponse = await Api.deleteSharedUser(dataSetToBeDeleted.datasetId!);

      clearSharedDataset();
      return deleteSharedUserResponse;
    } catch (error) {
      console.error('Error deleting dataset:', error);
    } finally {
      setIsDeleting(false);
      closeDatasetModal();
    }
  }, [dataSetToBeDeleted, clearSharedDataset]);

  const deleteDataset = useCallback(async () => {
    setIsDeleting(true);
    try {
      await Api.deleteDataset(dataSetToBeDeleted.datasetId!);
      clearSelectedDataSet();
      clearCurrentDataset();
    } catch (error) {
      console.error('Error deleting dataset:', error);
    } finally {
      setIsDeleting(false);
      closeDatasetModal();
      setDataSetToBeDeleted({ datasetId: null, datasetName: null, isSharedDataset: false });
    }
  }, [dataSetToBeDeleted, clearSelectedDataSet, clearCurrentDataset]);

  const closeDatasetModal = () => {
    setIsDeleteDatasetModalOpen(false);
    setDataSetToBeDeleted({ datasetId: null, datasetName: null, isSharedDataset: false });
  };
  useEffect(() => {
    // Fetch data and update user on component mount
    const fetchUserData = async () => {
      const fetchUser = await User.getInstance();
      Object.assign(user, fetchUser);
    };

    fetchUserData();
  }, []);

  return (
    <div style={{ padding: '16px' }}>
      <Typography variant="h6" style={{ margin: '0' }}>
        {datasetNameToShow}
      </Typography>
      <List>
        {!isItemVisible && (
          <ListItem
            sx={{
              color: '#256AFF',
              transition: 'all 300ms ease-in-out',
              textDecoration: 'underline',
              padding: 0,
              cursor: 'pointer',
            }}
          >
            <ListItemText>{t('rightPanel.rename')}</ListItemText>
          </ListItem>
        )}

        <ListItem
          sx={{
            color: '#256AFF',
            transition: 'all 300ms ease-in-out',
            textDecoration: 'underline',
            padding: 0,
            cursor: 'pointer',
          }}
        >
          <ListItemText>{t('rightPanel.favourite')}</ListItemText>
        </ListItem>

        <ListItem
          sx={{
            color: '#256AFF',
            transition: 'all 300ms ease-in-out',
            textDecoration: 'underline',
            padding: 0,
            cursor: 'pointer',
          }}
        >
          <ListItemText>{t('rightPanel.viewChangeCont')}</ListItemText>
        </ListItem>

        {isItemVisible && (
          <ListItem
            sx={{
              color: '#256AFF',
              transition: 'all 300ms ease-in-out',
              textDecoration: 'underline',
              padding: 0,
              cursor: 'pointer',
            }}
          >
            <ListItemText onClick={handleUnload}>{t('rightPanel.unload')}</ListItemText>
          </ListItem>
        )}
      </List>

      <List>
        {ownerListDataset.length > 0 ? (
          ownerListDataset.map((ownerDatasetUser, index) => (
            <ListItem sx={{ padding: 0, justifyContent: 'space-between' }} key={index}>
              <ListItemText sx={{ marginRight: '50px' }}>{ownerDatasetUser}</ListItemText>
              <ListItemText sx={{ textAlign: 'right' }}>
                <span
                  style={{
                    fontSize: '12px',
                    background: 'var(--global-color)',
                    color: '#fff',
                    padding: '2px 5px 3px',
                    borderRadius: '4px',
                  }}
                >
                  {t('rightPanel.dataset.ownerBadge')}
                </span>
              </ListItemText>
            </ListItem>
          ))
        ) : (
          <Typography>{t('rightPanel.dataset.ownersNotFoudMessage')}</Typography>
        )}
      </List>
      <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '10px' }} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          <SvgIcon sx={{ marginRight: '10px' }} component={AccountCircle} />
          <Typography>{t('rightPanel.dataset.sharedHeading')}</Typography>
        </Box>
        <Typography sx={{ fontWeight: 600 }}>{sharedListDataset.length}</Typography>
      </Box>
      <List sx={{ marginLeft: '25px' }}>
        {sharedListDataset.length > 0 ? (
          sharedListDataset.map((sharedDatasetUser, index) => (
            <ListItem sx={{ padding: 0, justifyContent: 'space-between' }} key={index}>
              <ListItemText>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', width: '200px', wordBreak: 'break-all' }}>
                    <SvgIcon sx={{ marginRight: '5px' }} component={BoyOutlined} />
                    <Typography sx={{ flex: 'none', fontWeight: '600', maxWidth: '170px' }}>
                      {sharedDatasetUser.name}
                    </Typography>
                  </Box>
                  {ownerListDataset.includes(user.fullName) && (
                    <ListItemText sx={{ flex: 'none' }}>
                      <ImageButton
                        srcFile={assets.removeUser}
                        handleClick={() =>
                          openDeleteModal({
                            datasetId: sharedDatasetUser.id,
                            datasetName: sharedDatasetUser.name,
                            isSharedDataset: true,
                          })
                        }
                      />
                    </ListItemText>
                  )}
                </Box>
              </ListItemText>
            </ListItem>
          ))
        ) : (
          <Typography>{t('rightPanel.dataset.shareNotFoudMessage')}</Typography>
        )}
      </List>

      {ownerListDataset.includes(user.fullName) && (
        <>
          <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '10px' }} />
          <Button
            sx={{ color: 'red', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() =>
              openDeleteModal({ datasetId: datasetIdToShow, datasetName: datasetNameToShow, isSharedDataset: false })
            }
          >
            {t('rightPanel.deleteDataset')}
          </Button>
        </>
      )}

      {isDeleteDatasetModalOpen && (
        <DeleteModal
          openDeleteModal={isDeleteDatasetModalOpen}
          closeDeleteModal={closeDatasetModal}
          modalAPi={() => {
            return dataSetToBeDeleted.isSharedDataset ? deleteDatasetSharedUser() : deleteDataset();
          }}
          dialogTitle={deleteTitleName}
          textContent={modifiedTextContent}
        />
      )}
      {isDeleting && <CircularProgress size={20} />}
    </div>
  );
};

export default EditDataset;
