import Auth from '../../helpers/Auth';
import { useEffect, useState, useContext, useRef } from 'react';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';
import Header from './Header';
import ResponseBox from './ResponseBox';
import UserContent from './UserContent';
import ResponseContent from './ResponseContent';
import { useTheme, useMediaQuery, Box, Typography } from '@mui/material';
import '../../css/ChatInterface.css';
import QueryBox from './QueryBox';
import User from '../../helpers/User';
import Api, { AgentChatMessages, ChatMessages, SkillParameters } from '../../data/api/Api';
import { Skill } from '../../data/models/Skill';
import { LlmModel } from '../../data/models/LlmModel';
import { RightNavPanel } from '../../components/rightPanel/rightNavPanel/RightNavPanel';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import { setSuccessResponseModal } from '../../reduxStore/slices/fileUploadSlice';
import { setInitialHistoryState } from '../../reduxStore/slices/historySlice';
import { RecentChatList } from './RecentChatList';
import { useLocation, useNavigate } from 'react-router-dom';
import FeaturedList from './FeaturedList';
import { NoAccessToInnovationCenterHTML } from '../../components/staticComponents/StaticHtmlGenerator';
import ConversationGuides from './ConversationGuides';
import InnovationCenter from '../../components/staticComponents/InnovationCenter';
import customAppsData from '../../components/rightPanel/subpanel/customApps/CustomApps.json';
import { showUserActionContentOnChat } from '../../reduxStore/slices/NotifyUserActionContentSlice';

export function Chat() {
  const dispatch = useAppDispatch();
  const conversationIdRef = useRef('');
  const conversationMessageCountRef = useRef(0);
  const { activeNavItem, setActiveNavItem } = useContext(ActiveNavItemContext);
  const { t } = useTranslation();
  const subPanelOpen =
    activeNavItem !== null && activeNavItem !== t('welcomepage.Home') && activeNavItem !== t('welcomepage.Chat');
  const [user, setUser] = useState(User.empty);
  const [chatContents, setChatContents] = useState<JSX.Element[]>([]);
  const [messages, setMessages] = useState<ChatMessages[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appMessage, setAppMessage] = useState('');
  let currmessages: ChatMessages[] = [];
  const { enqueueSnackbar } = useSnackbar();
  const { userActionContent } = useAppSelector((state) => state.notifyUserActionContent);
  const { activeAgentId, activeAgentModelName } = useAppSelector((state) => state.agents.activeAgent);
  const { activeDataSetId } = useAppSelector((state) => state.dataset.selectedDataSet);
  const { fileUpload } = useAppSelector((state) => state.fileUpload);
  const { historyResponse, conversationID, conversationMessageCounter, selectedModelHistory } = useAppSelector(
    (state) => state.history.historyState
  );
  const { innovationCenter, customAppDatasetId } = useAppSelector((state) => state.featured);
  const temperature = 0.3;
  const [selectedGuide, setSelectedGuide] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const isChatPage = location.pathname.replace('/', '').toLowerCase() === 'chat';
  const isWelcomePage = location.pathname.replace('/', '').toLowerCase() === 'welcomepage';

  const onGuideClick = (customText: string) => {
    setSelectedGuide(customText);
  };
  const [selectedModel, setSelectedModel] = useState(LlmModel.standard);
  const [llmModels, setLlmModels] = useState<LlmModel[]>([LlmModel.standard]);

  useEffect(() => {
    const modelFound = llmModels.find((model) => model.id === selectedModelHistory);
    setSelectedModel(modelFound ? modelFound : LlmModel.standard);
  }, [selectedModelHistory]);

  useEffect(() => {
    if (historyResponse) {
      historyResponse.map((message) => {
        if (message.name.includes(`${user.initials()}:`)) {
          const userContent = (
            <UserContent
              initials={user.initials()}
              message={message.content}
              imageSelectionChange={() => 'imagePath'}
              toolTipTextChange={(value) => `Tooltip for ${value}`}
            />
          );
          handleAddChatContent(userContent);
        } else {
          const modelData = message.name.split(':');
          const selectedModel = llmModels.find((model) => model.id === modelData[0]);
          if (selectedModel) {
            const initialResponseContent = (
              <ResponseContent
                modelIcon={selectedModel.image}
                modelTooltip={selectedModel.tooltip}
                appMessage={message.content}
                isLoading={false}
              />
            );
            handleAddChatContent(initialResponseContent);
          }
        }
      });
    }
    conversationIdRef.current = conversationID;
    conversationMessageCountRef.current = conversationMessageCounter;
  }, [historyResponse, user]);

  useEffect(() => {
    if (userActionContent.length) {
      const initialResponseContent = (
        <ResponseContent
          appMessage={userActionContent}
          modelIcon={selectedModel.image}
          modelTooltip={selectedModel.tooltip}
          isLoading={false}
        />
      );
      handleAddChatContent(initialResponseContent);
      dispatch(showUserActionContentOnChat(''));
    }
    if (innovationCenter.isActive && isWelcomePage) {
      // This line checks if the user is currently on the 'apps' tab,has access to the Innovation Center
      //and present on welcome page at that moment.
      navigate('/Chat');
      setChatContents([]);
      innovationCenter.userHasAccess
        ? handleAddChatContent(<InnovationCenter />)
        : handleAddChatContent(NoAccessToInnovationCenterHTML());
    }

    //clear the previous messages while switching between different custom apps and set system propmt message.
    if (innovationCenter.isActive && customAppDatasetId) {
      const selectedCustomApp = customAppsData.custom_apps.find(
        (customApp) => customApp.dataset_id === customAppDatasetId
      );
      if (selectedCustomApp) {
        setMessages([{ content: selectedCustomApp.system_prompt, role: 'system' }]);
      }
    }
  }, [userActionContent, activeNavItem, customAppDatasetId]);

  useEffect(() => {
    // Fetch data and update user on component mount
    const fetchData = async () => {
      (await Auth).login().catch((error) => {
        enqueueSnackbar(error);
      });

      // Update the user
      const user = await User.getInstance();
      setUser(user);
      await Api.listLlmModels().then((response) => {
        setLlmModels(response);
      });
    };

    if (isWelcomePage) {
      setActiveNavItem(t('welcomepage.Home'));
    } else if (isChatPage) {
      setActiveNavItem(t('welcomepage.Chat'));
    }

    fetchData();
  }, []);

  const updateMessages = (newMessages: ChatMessages[]) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { content: newMessages[0].content, role: newMessages[0].role, ...(activeAgentId && { name: user.initials() }) },
    ]);
    currmessages.push(...newMessages);
  };

  const clearChatHistory = () => {
    setMessages([]);
    setChatContents([]);
    conversationIdRef.current = '';
    conversationMessageCountRef.current = 0;
    dispatch(setSuccessResponseModal(false));
    dispatch(
      setInitialHistoryState({
        conversationID: '',
      })
    );
  };

  const handleAddChatContent = (content: JSX.Element) => {
    setChatContents((prevContents) => [...prevContents, content]);
  };

  const handleUpdateLastChatContent = (content: JSX.Element) => {
    setChatContents((prevContents) => {
      const newContents = [...prevContents];
      newContents[newContents.length - 1] = content;
      return newContents;
    });
  };
  // Function to truncate a string to a specified number of words.
  const truncateString = (inputString: string, wordCount: number): string => {
    // Split the input string into an array of words.
    const words = inputString.split(' ');

    // Check if the number of words in the input string exceeds the specified word count.
    if (words.length > wordCount) {
      // If so, slice the array to contain only up to the specified number of words,
      // join these words back into a string, and append an ellipsis ('...') to indicate truncation.
      return words.slice(0, wordCount).join(' ') + '...';
    }
    // If the input string contains wordCount words or fewer, return it unchanged.
    return inputString;
  };

  //this hanldes whether to use default query api or ai assets api.
  const handlePostQueryApi = (model: LlmModel) => {
    const skill_parameters: SkillParameters = {
      temperature: temperature,
    };

    //if user changes llm model after activating agent, then pass chosen model under skill parameter object;
    if (activeAgentId && activeAgentModelName !== model.id && model.id !== LlmModel.standard.id) {
      skill_parameters.model_name = model.id as SkillParameters['model_name'];
    }

    return activeAgentId
      ? Api.postAgentQuery(messages as AgentChatMessages[], activeAgentId, 'Agent', skill_parameters)
      : Api.postQuery(activeDataSetId, Skill.completion, messages, model, skill_parameters, customAppDatasetId);
  };

  let newContent = '';
  const checkRegex = new RegExp('<details[^>]*>((.|s)*?)</details>');

  const handlePostQuery = (newQuery: string, model: LlmModel) => {
    if (conversationIdRef.current === '') {
      let message = newQuery;
      Api.addTitles(newQuery).then((response) => {
        conversationIdRef.current = response.data?.conversation_id as string;
        dispatch(
          setInitialHistoryState({
            conversationID: '',
          })
        );
        // Split the message string into an array of words, then filter out any falsy values (like empty strings).
        // This is done by splitting the message on spaces, and the .filter(Boolean) part effectively removes
        // any empty strings that might result from multiple consecutive spaces in the original message.
        // After filtering, check if the length (word count) is greater than 10.
        if (message.split(/\s+/).filter(Boolean).length > 10) {
          // If the message contains more than 10 words, truncate it to 5 words.
          // This uses the previously defined truncateString function.
          message = truncateString(message, 5);
        }
        dispatch(
          setInitialHistoryState({
            activeHistory: message,
          })
        );
      });
    }
    setIsLoading(true);
    setAppMessage('');
    currmessages = messages;
    updateMessages([{ content: newQuery, role: 'user', ...(activeAgentId && { name: user.initials() }) }]);
    const userContent = (
      <UserContent
        initials={user.initials()}
        message={newQuery}
        imageSelectionChange={() => 'imagePath'}
        toolTipTextChange={(value) => `Tooltip for ${value}`}
      />
    );

    handleAddChatContent(userContent);
    const initialResponseContent = (
      <ResponseContent
        appMessage=""
        modelIcon={selectedModel.image}
        modelTooltip={selectedModel.tooltip}
        isLoading={true}
      />
    );

    handleAddChatContent(initialResponseContent);

    handlePostQueryApi(model)
      .then(async (response) => {
        // TODO: Add error handling
        const body = response.response.body;
        if (response.response.status !== 200) {
          if (response.error?.message) {
            enqueueSnackbar(response.error.message);
          }
        }
        if (!body) {
          setIsLoading(false);
          return;
        }
        // Check if the current conversation ID is not empty.
        if (conversationIdRef.current !== '') {
          // Increment the conversation message count by 1.
          // This counter keeps track of the number of messages or queries sent during this conversation.
          conversationMessageCountRef.current = conversationMessageCountRef.current + 1;

          // Determine the skill name based on whether any files have been uploaded.
          // If there are files, use 'docCompletion' skill; otherwise, use 'completion' skill.
          // This likely affects how the conversation or query is processed on the backend.
          const skill_name = fileUpload.uploadedFiles.length > 0 ? Skill.docCompletion : Skill.completion;

          // Construct a dataset identifier using the user's initials and the determined skill name.
          // This identifier might be used for logging, analytics, or to tailor the conversation processing.
          const modelAPPDataset = `${user.initials()}:${skill_name}`;

          // Call the Api's updateConversation method to update the current conversation.
          // This method likely sends the new query along with the updated message count and dataset identifier
          // to the server, where it may be processed or logged accordingly.
          Api.updateConversation(
            conversationIdRef.current,
            newQuery,
            conversationMessageCountRef.current,
            modelAPPDataset
          );
        }

        try {
          const readableStream = body.pipeThrough(new TextDecoderStream());
          const reader = readableStream.getReader();

          if (!reader) {
            enqueueSnackbar(t('snackbar.alertMessage.readerNotFound'));
            setIsLoading(false);
            return;
          }

          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              break;
            }
            if (value === undefined) {
              continue;
            }

            const strings = value.split('\n');

            strings.forEach((string) => {
              if (string === '') {
                return;
              }
              const data = JSON.parse(string);
              newContent += data.data.content;
              setIsLoading(false);
              setAppMessage(newContent);
            });
          }
        } catch (error) {
          enqueueSnackbar(t('snackbar.alertMessage.queryErrorHandling'), { variant: 'error' });
          console.error(error);
          setIsLoading(false);
        }

        updateMessages([
          {
            content: newContent.replace(checkRegex, '').replaceAll('<br>', '\n'),
            role: 'assistant',
            ...(activeAgentId && { name: user.initials() }),
          },
        ]);
        // Check if there's an ongoing conversation by checking if a conversation ID exists.
        if (conversationIdRef.current) {
          // Increase the count of messages sent in this conversation by one.
          conversationMessageCountRef.current += 1;

          // Decide which skill to use based on whether any files have been uploaded.
          // If files are present, use the 'docCompletion' skill for document processing.
          // If no files are uploaded, use the 'completion' skill for standard queries.
          const skill_name = fileUpload.uploadedFiles.length > 0 ? Skill.docCompletion : Skill.completion;

          //This identifier ('modelAPPDataset') is crucial for storing
          // responses in a way that allows us to differentiate between various AI models and their
          // versions when reloading history
          //Currently, it's set to 'gpt-4' but this will be dynamic in the future
          const modelAPPDataset = selectedModelHistory + ':' + skill_name + ':' + activeDataSetId;

          // Call the Api's updateConversation method to update the current query response .
          Api.updateConversation(
            conversationIdRef.current,
            newContent,
            conversationMessageCountRef.current,
            modelAPPDataset
          );
        }
      })
      .catch(() => {
        setIsLoading(false);
        enqueueSnackbar(t('snackbar.alertMessage.internetDisconnected'));
      });
  };

  useEffect(() => {
    if (!isLoading) {
      const responseContent = (
        <ResponseContent
          appMessage={appMessage}
          isLoading={false}
          modelIcon={selectedModel.image}
          modelTooltip={selectedModel.tooltip}
        />
      );
      handleUpdateLastChatContent(responseContent);
    }
  }, [appMessage, isLoading]);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <div id="boundary-box">
        <Header clearChatHistory={clearChatHistory} />
        {isWelcomePage && (
          <Typography
            variant="h3"
            sx={{ marginBottom: '20px', color: '#793196', textAlign: 'center', fontWeight: 700 }}
          >
            {t('chatInterface.welcomeMessage', { name: user.firstName() })}
          </Typography>
        )}

        <Box
          sx={{
            marginRight: isLargeScreen ? (subPanelOpen ? '26.5rem' : '100px') : '100px',
            transition: 'all 200ms ease-in-out',
          }}
        >
          {isWelcomePage && <FeaturedList />}

          {isChatPage && (
            <ResponseBox
              userFirstName={user.firstName()}
              dynamicContent={chatContents}
              modelIcon={selectedModel.image}
              modelTooltip={selectedModel.tooltip}
            />
          )}
          <QueryBox
            userInitials={user.initials()}
            postQuery={handlePostQuery}
            clearChatHistory={clearChatHistory}
            selectedGuide={selectedGuide}
          />
          {isWelcomePage && <ConversationGuides onGuideClick={onGuideClick} />}

          {isWelcomePage && <RecentChatList />}
        </Box>
        <RightNavPanel userFullName={user.fullName} initials={user.initials()} />
      </div>
    </>
  );
}
