import React, { useEffect, useState, useCallback } from 'react';
import Api from '../../../../data/api/Api';
import CircularProgress from '@mui/material/CircularProgress';
import SearchList from '../../../sharedComponents/SearchList';
import BasicPopover from '../../../sharedComponents/BasicPopover';
import EditDataset from './EditDataset';
import * as assets from '../../../../assets';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/redux-hooks';
import { selectedDataSet, ownerDataSet, sharedDataSet } from '../../../../reduxStore/slices/DataSetSlice';
import { useTranslation } from 'react-i18next';
import ImageButton from '../../../sharedComponents/ImageButton';
import { useSnackbar } from 'notistack';
import { showUserActionContentOnChat } from '../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { buttonStyle } from './Dataset';

interface Acl {
  users: string[]; // Array of user IDs
}
interface Dataset {
  _id: string;
  name: string;
  owners: string[];
  acl: Acl;
}
interface SearchListItem {
  _id: string;
  name: string;
  owners: string[];
  users: string[];
}
export interface datasetSharedUser {
  id: string;
  name: string;
}

const DatasetList: React.FC = () => {
  const dispatch = useAppDispatch();

  const { activeDataSetName, activeDataSetId } = useAppSelector((state) => state.dataset.selectedDataSet);

  const [datasets, setDatasets] = useState<SearchListItem[]>([]);
  const [prevDataSetName, setprevDataSetName] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [ownersData, setOwnersData] = useState<{ [key: string]: string[] }>({});
  const [datasetSharedUsersList, setDatasetSharedUsersList] = useState<{ [key: string]: datasetSharedUser[] }>({});

  const fetchListofDatasets = useCallback(async () => {
    try {
      const datasetApiResponse = await Api.listDatasets();
      const datasets = datasetApiResponse.data as Dataset[];
      const filteredAndSortedDatasets = datasets
        .filter((dataset) => dataset.name)
        .sort((a, b) => a.name.localeCompare(b.name));

      if (!filteredAndSortedDatasets.length) enqueueSnackbar(t('snackbar.alertMessage.noDataset'));

      const searchListItems = filteredAndSortedDatasets.map((dataset) => ({
        _id: dataset._id,
        name: dataset.name,
        owners: dataset.owners,
        users: dataset.acl?.users || [], // Fetch users from acl
      }));

      setDatasets(searchListItems);
    } catch (err) {
      enqueueSnackbar((err as Error).toString());
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    fetchListofDatasets();
  }, [fetchListofDatasets]);

  const getGraphData = useCallback(
    async (dataset: SearchListItem) => {
      try {
        // Fetch user details using the acl.users from the original Dataset interface
        const aclResponse = await Api.getUserDetailsByIds(dataset.users);
        const datasetSharedList: datasetSharedUser[] = dataset.users.map((id) => ({
          id,
          name: aclResponse[id] || id,
        }));

        const ownersName = await Api.getUserDetailsByIds(dataset.owners);
        const ownerList = dataset.owners.map((id) => ownersName[id] || id);

        //for local data fetch
        setOwnersData((prev) => ({ ...prev, [dataset._id]: ownerList }));
        setDatasetSharedUsersList((prev) => ({ ...prev, [dataset._id]: datasetSharedList }));

        //for redux data fetch
        dispatch(
          ownerDataSet({
            ownersDataList: { ...ownersData, [dataset._id]: ownerList },
          })
        );
        dispatch(
          sharedDataSet({
            sharedDataList: { ...datasetSharedUsersList, [dataset._id]: datasetSharedList },
          })
        );
      } catch (error) {
        enqueueSnackbar(t('snackbar.alertMessage.errorGetGraphData'));
        console.log(error);
      }
    },
    [dispatch, enqueueSnackbar, t, ownersData, datasetSharedUsersList]
  );

  useEffect(() => {
    // Effect for handling dataset name changes
    if (activeDataSetId && activeDataSetName !== prevDataSetName) {
      dispatch(showUserActionContentOnChat(t('fileUpload.activeMessage', { fileName: activeDataSetName })));
      setprevDataSetName(activeDataSetName);
    } else if (activeDataSetId === '' && activeDataSetName !== prevDataSetName) {
      dispatch(showUserActionContentOnChat(t('fileUpload.unloadMessage', { fileName: prevDataSetName })));
      setprevDataSetName('');
    }
  }, [activeDataSetName, activeDataSetId, prevDataSetName]);

  const handleDatasetSelect = (dataset: SearchListItem) => {
    dispatch(
      selectedDataSet({
        activeDataSetName: dataset.name,
        activeDataSetId: dataset._id,
      })
    );
    if (!ownersData[dataset._id] || !datasetSharedUsersList[dataset._id]) {
      getGraphData(dataset);
    }
  };

  const renderEditPopover = (dataset: SearchListItem) => {
    const handleOpenPopover = () => {
      if (!ownersData[dataset._id] || !datasetSharedUsersList[dataset._id]) {
        getGraphData(dataset);
      }
    };

    return (
      <BasicPopover
        srcComponent={<ImageButton srcFile={assets.dots} style={buttonStyle} />}
        onOpen={handleOpenPopover}
        contentComponent={
          <EditDataset
            activeDataSetName={dataset.name}
            activeDataSetId={dataset._id}
            currentDatasetId={dataset._id}
            currentDatasetName={dataset.name}
            isFromDatasetList={true}
            isItemVisible={false}
            ownerListDataset={ownersData[dataset._id] || []}
            sharedListDataset={datasetSharedUsersList[dataset._id] || []}
          />
        }
      />
    );
  };

  return (
    <Box>
      {loading ? (
        <CircularProgress size={20} sx={{ marginTop: '10px' }} />
      ) : (
        <>
          <SearchList
            label={t('rightPanel.labelName')}
            onSelectItem={handleDatasetSelect}
            showDetailsOnHover={true}
            items={datasets}
            renderPopover={renderEditPopover}
          />
        </>
      )}
    </Box>
  );
};

export default DatasetList;
