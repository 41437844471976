import Api from '../../data/api/Api';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';
import { Avatar, Box, Button, Card, CardContent, Chip, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import customAppsData from '../../components/rightPanel/subpanel/customApps/CustomApps.json';
import { useAppDispatch } from '../../reduxStore/redux-hooks';
import { saveInnovationCenterDetails, saveListOfCustomAppsUserHasAccess } from '../../reduxStore/slices/FeaturedSlice';
import { ListOfExistingDataset } from '../../components/rightPanel/subpanel/dataset/DatasetModal';
import { featuredList, subFeaturedList } from '../../components/staticComponents/StaticHtmlGenerator';

interface FeaturedSubList {
  name: string;
  icon: string;
  description?: string;
  lastUsed?: string;
  enabled: boolean;
}

interface FeaturesType {
  featureId: string;
  featureName: string;
  featureBackgroundColor: string;
  featureDisable: boolean;
  featureIcon: string;
}

function FeaturedList() {
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const [isDatasetsLoading, setIsDatasetsLoading] = useState(false);
  const [showSubFeaturedPanel, setShowSubFetauredPanel] = useState(false);
  const [selectedFeatureList, setSelectedFeatureList] = useState<FeaturedSubList[]>([]);
  const selectedFeatureId = useRef<string>('');

  // List of featured items to be displayed

  const dispatch = useAppDispatch();

  // useEffect hook to fetch datasets for custom apps access on component mount
  useEffect(() => {
    fetchDatasetsForCustomAppsAccess();
  }, []);

  // Function to fetch datasets for custom apps access
  const fetchDatasetsForCustomAppsAccess = async () => {
    setIsDatasetsLoading(true);
    // Get custom apps details from a JSON file
    const customAppsDetails = customAppsData.custom_apps;
    // Fetch list of datasets from the API
    const listOfDatasets = (await Api.listDatasets()).data as ListOfExistingDataset[];
    // Filter custom apps based on user's access to datasets
    const listOfCustomAppsUserHasAccess = customAppsDetails.filter((customApp) =>
      listOfDatasets.some((dataset) => dataset._id === customApp.dataset_id)
    );
    // If the user has access to any app under Innovation Centers app, dispatch an action to save the details in the Redux store
    if (listOfCustomAppsUserHasAccess.length) {
      dispatch(
        saveInnovationCenterDetails({
          userHasAccess: true,
        })
      );
      dispatch(saveListOfCustomAppsUserHasAccess(listOfCustomAppsUserHasAccess));
    }
    setIsDatasetsLoading(false);
  };

  // Function to handle clicks on featured items
  const handleFeaturedClick = (feature: FeaturesType) => {
    if (!showSubFeaturedPanel) {
      setShowSubFetauredPanel(true);
    }
    const associatedSubFeatures = subFeaturedList.find(
      (subFeature) => subFeature.associatedFeatureId === feature.featureId
    );
    if (associatedSubFeatures) {
      selectedFeatureId.current = feature.featureId;
      setSelectedFeatureList(associatedSubFeatures.associatedSubFeatures);
    }
  };

  const handleSubFeatureClick = () => {
    dispatch(
      saveInnovationCenterDetails({
        isActive: true,
      })
    );
    setActiveNavItem('apps');
  };

  return (
    <Box sx={{ marginBottom: '160px' }}>
      <Typography sx={{ marginBottom: '15px' }}>Featured Workspace</Typography>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'left' }}>
        {featuredList.map((feature, index) => {
          return (
            <Chip
              key={index}
              disabled={feature.featureDisable || isDatasetsLoading}
              avatar={<Avatar alt="Natacha" src={feature.featureIcon} />}
              sx={{
                padding: '8px 15px',
                background: selectedFeatureId.current === feature.featureId ? 'rgba(0,0,0,0.04)' : '',
              }}
              variant="outlined"
              onClick={() => handleFeaturedClick(feature)}
              label={feature.featureName}
            />
          );
        })}
      </Box>
      {showSubFeaturedPanel && (
        <Box sx={{ display: 'flex', gap: 2, marginLeft: '0', justifyContent: 'left', marginTop: '1.25rem' }}>
          {selectedFeatureList.map((subFeature, index) => {
            return (
              <Card
                key={index}
                sx={{
                  pointerEvents: !subFeature.enabled ? 'none' : 'auto',
                  cursor: !subFeature.enabled ? 'not-allowed' : 'pointer',
                  opacity: !subFeature.enabled ? 0.5 : 1,
                  border: '1px solid #E5E5E5',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflow: 'visible',
                  width: '20%',
                }}
              >
                <CardContent sx={{ paddingBottom: 0 }}>
                  <Typography sx={{ fontWeight: 500 }}>{subFeature.name}</Typography>
                  <Typography sx={{ fontWeight: 400, fontSize: '12px', marginTop: '5px' }}>
                    {subFeature.description}
                  </Typography>
                </CardContent>
                <Box sx={{ textAlign: 'right', padding: '15px 15px 10px 15px' }}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ color: '#793196', borderColor: '#793196' }}
                    onClick={handleSubFeatureClick}
                  >
                    start
                  </Button>
                </Box>
              </Card>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

export default FeaturedList;
