import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../reduxStore/redux-hooks';
import { activeAgent } from '../../../../../reduxStore/slices/AgentsSlice';
import DeleteModal from '../../../../sharedComponents/DeleteModal';
import Api from '../../../../../data/api/Api';
import { MyAgentsData } from '../myAgents/MyAgents';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { SectionDivider } from '../../../../staticComponents/StaticHtmlGenerator';

interface EditAgentProps {
  agent: MyAgentsData;
  isActiveAgent: boolean;
  refreshMyAgentsList?: () => void;
}

const EditAgent: React.FC<EditAgentProps> = ({ agent, isActiveAgent, refreshMyAgentsList }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [isDeleteAgentModalOpen, setIsDeleteAgentModalOpen] = useState<boolean>(false);

  // Function to unload the active agent
  const unloadActiveAgent = () => {
    // Show a user action content on the chat
    dispatch(showUserActionContentOnChat(t('rightPanel.agent.deactivateAgent', { activeAgentName: agent.name })));

    // Dispatch an action to set the active agent to an empty object
    dispatch(
      activeAgent({
        activeAgentName: '',
        activeAgentId: '',
        activeAgentModelName: '',
      })
    );
  };

  // List of options for editing the agent
  const editAgentOptionsList = [
    { label: t('rightPanel.agent.editAgent.customize'), sectionDivider: false, onClick: () => {} }, // Option to customize the agent
    {
      label: isActiveAgent ? t('rightPanel.agent.editAgent.unloadAgent') : t('rightPanel.favourite'), // Option to unload or favorite the agent
      sectionDivider: true,
      onClick: isActiveAgent ? unloadActiveAgent : () => {},
    },
    { label: t('rightPanel.agent.editAgent.manageShare'), sectionDivider: true, onClick: () => {} }, // Option to manage the sharing of the agent
  ];

  // Styles for the edit agent list items
  const editAgentListStyles = {
    color: '#256AFF',
    transition: 'all 300ms ease-in-out',
    textDecoration: 'underline',
    padding: 0,
    cursor: 'pointer',
  };

  // Function to confirm the deletion of the agent
  const confirmDeleteAgent = async () => {
    try {
      const deleteAgentResponse = await Api.deleteAgent(agent._id);

      // If the agent was deleted successfully
      if (deleteAgentResponse) {
        dispatch(showUserActionContentOnChat(t('rightPanel.agent.deleteAgent', { activeAgentName: agent.name })));

        if (isActiveAgent) {
          dispatch(
            activeAgent({
              activeAgentName: '',
              activeAgentId: '',
              activeAgentModelName: '',
            })
          );
        }

        // Refresh the list of agents if the function is provided
        refreshMyAgentsList?.();
      }
    } catch (error) {
      console.error('Error deleting agent:', error);
    } finally {
      setIsDeleteAgentModalOpen(false);
    }
  };

  // Function to open the delete agent modal
  const deleteAgent = () => {
    setIsDeleteAgentModalOpen(true);
  };

  // Function to close the delete agent modal
  const closeDeleteAgentModal = () => {
    setIsDeleteAgentModalOpen(false);
  };

  return (
    <Box style={{ padding: '20px' }} borderRadius={'6px'}>
      <Typography fontWeight={'700'} fontSize={'18px'} color={'#351A55'}>
        {agent.name}
      </Typography>
      <List sx={{ padding: 0 }}>
        {editAgentOptionsList.map((item, index) => (
          <Box key={index}>
            <ListItem key={index} sx={editAgentListStyles}>
              <ListItemText onClick={item.onClick}>{item.label}</ListItemText>
            </ListItem>
            {item.sectionDivider && <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '8px' }} />}
          </Box>
        ))}
      </List>
      <Button
        sx={{
          color: '#BE266A',
          fontSize: '1rem',
          cursor: 'pointer',
          textTransform: 'none',
          textDecoration: 'underline',
          padding: '0',
        }}
        onClick={deleteAgent}
      >
        {t('rightPanel.agent.editAgent.deleteAgent')}
      </Button>
      {isDeleteAgentModalOpen && (
        <DeleteModal
          openDeleteModal={isDeleteAgentModalOpen}
          closeDeleteModal={closeDeleteAgentModal}
          modalAPi={confirmDeleteAgent}
          dialogTitle={t('rightPanel.agent.editAgent.deleteAgent')}
          textContent={`${t('rightPanel.confirmDelete')} ${agent.name}?`}
        />
      )}
    </Box>
  );
};

export default EditAgent;
