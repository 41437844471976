import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import './CreateAgent.css';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../data/api/Api';
import { LlmModel } from '../../../../../data/models/LlmModel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import * as assets from '../../../../../assets';
import { Controller, useForm } from 'react-hook-form';
import { ActiveNavItemContext } from '../../../RightPanelContext';
import { useAppDispatch } from '../../../../../reduxStore/redux-hooks';
import { agentActivationProcess } from '../../../../../reduxStore/slices/AgentsSlice';

interface CreateAgentData {
  name: string;
  _id: string;
  model_name: string;
}
interface CreateAgentDataResponse {
  data: CreateAgentData;
  response: Response;
}

enum AgentType {
  Basic = 'Basic',
  Dataset = 'Dataset',
  Toolset = 'Toolset',
}

function CreateAgent() {
  const { t } = useTranslation();
  const [llmModels, setLlmModels] = useState([LlmModel.standard]);
  const [accordionExpanded, setAccordionExpanded] = React.useState<string | false>(false);
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const listOfCreateAgentAccordions = ['define-skills', 'attach-dataset', 'share-agent'];
  const dispatch = useAppDispatch();
  const temperature = 0.3;

  //register create agent form fields with default values to useForm.
  //useForm is a custom hook for managing forms with ease.
  const { watch, control, getValues } = useForm({
    defaultValues: {
      dataset_id: null,
      description: '',
      instructions: '',
      model_name: llmModels[0].id as any, //type is defined as 'any' since model standard is something that is defined
      // only in UI and that model do not get generated in the schema file automatically.
      name: '',
      temperature: temperature,
      tools: null,
      type: AgentType.Basic,
    },
  });

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  const handleCreateAgentFormSubmit = async () => {
    const createAgentFormData = getValues();
    //right now we create a default basic agent

    //An agent can be created using only either one of these options Basic/Toolset/Dataset, we cannot have combination of these.
    if (createAgentFormData.dataset_id) {
      //if an agent to be a dataset agent then create a dataset with dataset endpoint and pass the dataset id to dataset_id
      //then define type as 'Dataset' (this feature yet be implemented).
      createAgentFormData.type = AgentType.Dataset;
    } else if (createAgentFormData.tools) {
      //if an agent to be a toolset agent then define the tools and define type as 'Toolset' (this feature yet be implemented).
      createAgentFormData.type = AgentType.Toolset;
    }

    //standard should basically point to a default model, which is 'gpt-4'.
    if (createAgentFormData.model_name === 'standard') {
      createAgentFormData.model_name = t('llmModels.gpt4');
    }

    try {
      const createAgentResponse = (await Api.createAgent(createAgentFormData)) as CreateAgentDataResponse;
      if (createAgentResponse) {
        //once agents is successfully created hold these agent details to show information on the chat for the user to perform some action.
        dispatch(
          agentActivationProcess({
            ShowAgentActivationDialogOnChat: true,
            activationAgent: {
              activationAgentName: createAgentResponse.data.name,
              activationAgentId: createAgentResponse.data._id,
              activationAgentModelName: createAgentResponse.data.model_name,
            },
          })
        );
      }
    } catch (error) {
      console.error('Error in creating Agent', error);
    } finally {
      //close the right panel after agent created successfully.
      setActiveNavItem(null);
    }
  };

  const closeSubPanel = () => {
    setActiveNavItem(null);
  };

  useEffect(() => {
    // Load available LLM Models
    Api.listLlmModels().then((response) => {
      setLlmModels(response);
    });
  }, []);

  return (
    <Box component="form" className="create-agent-form">
      <Box className="create-agent-header">
        <Typography>{t('rightPanel.agent.createAgent.createNewAgent')}</Typography>
      </Box>
      <Box marginBottom={'40px'} className="agent-name-field">
        <Box marginLeft={'-106px'}>
          <img width={66} height={66} src={assets.agentIconEdit} alt="agent edit icon" />
        </Box>
        <Box flexGrow={1}>
          <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.nameOfAgent') + '*'}</InputLabel>
          {/* The Controller from react-hook-form is used into our form,
          providing it with the necessary props and state to manage its 
          value, validation, and error handling, */}
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                fullWidth
                placeholder={t('rightPanel.agent.createAgent.placeHolderForAgentName')}
              />
            )}
          />
        </Box>
      </Box>
      <Box marginBottom={'40px'}>
        <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.description')}</InputLabel>
        <Controller
          control={control}
          name="description"
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              fullWidth
              placeholder={t('rightPanel.agent.createAgent.placeHolderForDescription')}
            />
          )}
        />
      </Box>
      <Box marginBottom={'40px'} display={'flex'} gap={'50px'}>
        <Box width={'50%'}>
          <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.languageModel')}</InputLabel>
          <Controller
            control={control}
            name="model_name"
            rules={{ required: false }}
            render={({ field }) => (
              <Select
                {...field}
                variant="standard"
                defaultValue={llmModels[0].id}
                sx={{ width: '50%', display: 'inline' }}
              >
                {llmModels.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>

        <Box width={'20%'}>
          <Box sx={{ display: 'flex' }}>
            <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.conversationStyle')}</InputLabel>
            <Icon sx={{ verticalAlign: 'middle', marginLeft: '5px' }}>
              <InfoIcon />
            </Icon>
          </Box>

          <Box sx={{ position: 'relative', marginTop: '15px' }}>
            <Box sx={{ position: 'absolute', top: '-10px', width: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className="slider-marks">{t('rightPanel.agent.createAgent.accurate')}</Typography>
                <Typography className="slider-marks">{t('rightPanel.agent.createAgent.creative')}</Typography>
              </Box>
            </Box>
            <Box>
              <Controller
                control={control}
                name="temperature"
                rules={{ required: false }}
                render={({ field }) => (
                  <Slider {...field} name={'temperature'} step={0.1} min={0} max={1} valueLabelDisplay="on"></Slider>
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box marginBottom={'40px'}>
        <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.agentInstructions') + '*'}</InputLabel>
        <Typography sx={{ fontSize: 14 }}>{t('rightPanel.agent.createAgent.agentInstructionsHelper')}</Typography>

        <Controller
          control={control}
          name="instructions"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              fullWidth
              placeholder={t('rightPanel.agent.createAgent.placeHolderForInstructions')}
            />
          )}
        />
      </Box>
      <Box marginBottom={'40px'} className="create-agent-accordion-section">
        {listOfCreateAgentAccordions.map((panel) => (
          <Accordion
            key={panel}
            expanded={accordionExpanded === panel}
            onChange={handleAccordionChange(panel)}
            className="create-agent-accordion"
          >
            {/* accordions disabled until the features present in it implemented. */}
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${panel}-content`}
              id={`${panel}-header`}
              disabled
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <span>
                  {t(
                    `rightPanel.agent.createAgent.${
                      panel === 'define-skills'
                        ? 'defineSkills'
                        : panel === 'attach-dataset'
                          ? 'attachDataset'
                          : 'shareAgent'
                    }`
                  )}
                </span>
                <img width={20} height={20} src={assets.validIconDisabled} alt="valid icon disabled" />
              </Box>
            </AccordionSummary>
            <AccordionDetails>{`${panel} details will be added soon...`}</AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Box className="create-agent-actions">
        <Button variant="text" sx={{ textTransform: 'none', textDecoration: 'underline' }} onClick={closeSubPanel}>
          {t('rightPanel.agent.createAgent.cancel')}
        </Button>
        <Button
          type="button"
          variant="contained"
          sx={{ textTransform: 'none', backgroundColor: '#793196' }}
          disabled={!watch('name') || !watch('instructions')}
          onClick={handleCreateAgentFormSubmit}
        >
          {t('rightPanel.agent.createAgent.createAgent')}
        </Button>
      </Box>
    </Box>
  );
}

export default CreateAgent;
