import { Box, Typography } from '@mui/material';
import React from 'react';
import DatasetList from './DatasetList';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../reduxStore/redux-hooks';
import BasicPopover from '../../../sharedComponents/BasicPopover';
import EditDataset from './EditDataset';
import * as assets from '../../../../assets';
import ImageButton from '../../../sharedComponents/ImageButton';
import { datasetSharedUser } from './DatasetList';

export const buttonStyle: React.CSSProperties = {
  padding: '3px 10px',
  border: '1px solid #000',
  cursor: 'pointer',
  boxShadow: '0 0 5px rgba(0,0,0,0.3)',
  lineHeight: '1',
};

function Dataset() {
  const { t } = useTranslation();

  const { activeDataSetName, activeDataSetId } = useAppSelector((state) => state.dataset.selectedDataSet);
  const { ownersDataList } = useAppSelector((state) => state.dataset.ownerDataSet);
  const { sharedDataList } = useAppSelector((state) => state.dataset.sharedDataSet);

  const ownerListDataset: string[] = ownersDataList[activeDataSetId] || [];
  const datasetSharedUsersList: datasetSharedUser[] = sharedDataList[activeDataSetId] || [];

  return (
    <Box>
      <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
        {t('rightPanel.activeDataset')}
      </Typography>
      <Box color={activeDataSetName ? '#000' : '#A6A6A6'} fontSize={'16px'} sx={{ marginTop: '10px' }}>
        {activeDataSetName && (
          <BasicPopover
            srcComponent={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography fontWeight={activeDataSetName ? '700' : '400'}>
                  {activeDataSetName ? activeDataSetName : t('rightPanel.noDataset')}
                </Typography>
                <Typography>
                  <Box>
                    <ImageButton srcFile={assets.dots} style={buttonStyle} />
                  </Box>
                </Typography>
              </Box>
            }
            contentComponent={
              <EditDataset
                activeDataSetName={activeDataSetName}
                activeDataSetId={activeDataSetId}
                currentDatasetId=""
                currentDatasetName=""
                isFromDatasetList={false}
                isItemVisible={true}
                ownerListDataset={ownerListDataset}
                sharedListDataset={datasetSharedUsersList}
              />
            }
          />
        )}
      </Box>
      <Box>
        <Typography sx={{ margin: '2rem 0 0' }} color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
          {t('rightPanel.myDatasets')}
        </Typography>
        <Box>
          <DatasetList />
        </Box>
      </Box>
      <Box>
        <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'} marginTop={'25px'}>
          Favorite
        </Typography>
      </Box>
      <Typography sx={{ p: 2, borderTop: '1px dashed grey', marginTop: '40px' }}></Typography>

      <Box>
        <Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }} color={'#256AFF'}>
          View/Manage Datasets
        </Typography>
      </Box>
    </Box>
  );
}

export default Dataset;
