import React, { useState, useEffect } from 'react';
import Login from './pages/Login';
import { Chat } from './pages/chat/Chat';
import Disclaimer from './pages/Disclaimer';
import './i18n';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Auth from './helpers/Auth';
import ProtectedRoute from './components/ProtectedRoute';

// MSAL imports
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { ActiveNavItemContext } from './components/rightPanel/RightPanelContext';

function App() {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null);
  const navigate = useNavigate();
  const [activeNavItem, setActiveNavItem] = useState<null | string>(null);

  useEffect(() => {
    // Initialize the Auth instance
    Auth.then(async (authInstance) => {
      setMsalInstance(authInstance.getMsalInstance());
      const navigateDisclaimer = await authInstance.shouldShowDisclaimer();

      const callbackId = authInstance.getMsalInstance().addEventCallback(async (event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS) {
          if (navigateDisclaimer) {
            navigate('/disclaimer');
            authInstance.acknowledgeDisclaimer();
          }
        } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
          navigate('/login');
        }
      });

      return () => {
        authInstance.getMsalInstance().removeEventCallback(callbackId ?? '');
      };
    });
  }, [navigate]);

  return (
    <div className="App">
      {msalInstance && (
        <MsalProvider instance={msalInstance}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/Chat"
              element={
                <ProtectedRoute>
                  <ActiveNavItemContext.Provider value={{ activeNavItem, setActiveNavItem }}>
                    <Chat />
                  </ActiveNavItemContext.Provider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/WelcomePage"
              element={
                <ProtectedRoute>
                  <ActiveNavItemContext.Provider value={{ activeNavItem, setActiveNavItem }}>
                    <Chat />
                  </ActiveNavItemContext.Provider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/disclaimer"
              element={
                <ProtectedRoute>
                  <Disclaimer />
                </ProtectedRoute>
              }
            />
          </Routes>
        </MsalProvider>
      )}
    </div>
  );
}

export default App;
