import { Box, SelectChangeEvent, useTheme } from '@mui/material';
import React, { useEffect, useState, useRef, useContext } from 'react';
import ClearChat from './ClearChat';
import QueryInput from './QueryInput';
import Api from '../../data/api/Api';
import { LlmModel } from '../../data/models/LlmModel';
import '../../i18n';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import QueryInputActions from './QueryInputActions';
import { setInitialHistoryState } from '../../reduxStore/slices/historySlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';
import { chatNavigation } from '../../components/staticComponents/StaticHtmlGenerator';

interface QueryBoxProps {
  userInitials: string;
  postQuery: (newQuery: string, model: LlmModel) => void;
  clearChatHistory: () => void;
  selectedGuide: string;
}

const QueryBox: React.FC<QueryBoxProps> = ({ postQuery, clearChatHistory, selectedGuide }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);

  //state
  const dispatch = useAppDispatch();
  const { selectedModelHistory } = useAppSelector((state) => state.history.historyState);
  const [llmModels, setLlmModels] = useState([LlmModel.standard]);
  const [selectedModel, setSelectedModel] = useState(LlmModel.standard);
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const [resetTranscripts, setResetTranscripts] = useState<boolean>(false);
  const childRef = useRef<{ clearInput: () => void } | null>(null);
  const theme = useTheme();
  const maxLength = 65536;
  const { uploadFile, uploadFileMessage } = useAppSelector((state) => state.fileUpload.fileUpload);
  const [isQueryInputFocused, setIsQueryInputFocused] = useState(true);
  const queryBoxRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const isChatPage = location.pathname.replace('/', '').toLowerCase() === t('welcomepage.Chat');
  const isWelcomePage = location.pathname.replace('/', '').toLowerCase() === t('welcomepage.welcomepage');

  /// Event Handlers
  const handleModelChange = (event: SelectChangeEvent) => {
    const model = llmModels.find((model) => model.id === event.target.value);
    setSelectedModel(model || LlmModel.standard);
    dispatch(
      setInitialHistoryState({
        selectedModelHistory: event.target.value.toString(),
      })
    );
  };

  // commented below code until we have good solution to close right panle on click on anywhere outside it.
  // const handleOutsideClickToClosePanel = (event: MouseEvent) => {
  //   if (queryBoxRef.current && !queryBoxRef.current.contains(event.target as Node)) {
  //     setIsQueryInputFocused(false);
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener('mousedown', handleOutsideClickToClosePanel);
  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClickToClosePanel);
  //   };
  // });

  const handleTranscript = () => {
    setResetTranscripts(true);
  };

  const clearChat = () => {
    // Add logic for clearing the chat
    clearChatHistory();
    dispatch(
      setInitialHistoryState({
        clearValue: true,
        activeHistory: '',
      })
    );
  };

  const __ask = async () => {
    if (isWelcomePage) {
      navigate(chatNavigation);
      setActiveNavItem(t('welcomepage.Chat'));
    }

    const newQuery = currentMessage ?? '';
    if (newQuery === '') {
      return;
    }
    postQuery(newQuery, selectedModel);
    if (childRef.current) {
      childRef.current.clearInput(); // Call the clearInput function in the child component
    }
    dispatch(
      setInitialHistoryState({
        clearValue: false,
      })
    );
    setCurrentMessage('');
    setResetTranscripts(true);
  };

  useEffect(() => {
    // Load available LLM Models
    Api.listLlmModels().then((response) => {
      setLlmModels(response);
    });
    // passing selectedGuide to QueryInput comp using setCurrentMessage
    if (selectedGuide) setCurrentMessage(selectedGuide);
  }, [selectedGuide]);

  useEffect(() => {
    //updating the interaction model based on history selection.
    const model = llmModels.find((model) => model.id === selectedModelHistory);
    setSelectedModel(model || LlmModel.standard);
  }, [selectedModelHistory]);

  return (
    <>
      <Box className="query-part">
        {uploadFile && (
          <Box
            sx={{
              background: '#FBF5FF',
              padding: '0.62rem',
              border: '1px solid #F6ECFE',
              borderRadius: '0.313rem',
              fontSize: '0.875rem',
              marginBottom: '0.75rem',
            }}
          >
            <strong style={{ marginRight: '1rem' }}>{uploadFileMessage}</strong>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          {isChatPage && <ClearChat clearChat={clearChat} />}

          <Box
            ref={queryBoxRef}
            onClick={() => setIsQueryInputFocused(true)}
            className="form-outer"
            sx={{
              [theme.breakpoints.down('sm')]: {
                marginRight: '0',
                marginBottom: '15px',
              },
              border: '1px solid #DCDCDC',
              borderBottom: isQueryInputFocused ? '3px solid #793196' : '',
              borderRadius: '4px',
            }}
          >
            <QueryInput
              handleTranscript={handleTranscript}
              currentMessage={currentMessage}
              maxLength={maxLength}
              submitQuery={__ask}
              ref={childRef}
              setCurrentMessage={setCurrentMessage}
            />

            <QueryInputActions
              disabled={!currentMessage}
              maxLength={maxLength}
              currentMessage={currentMessage}
              selectedModel={selectedModel}
              llmModels={llmModels}
              resetsTranscript={resetTranscripts}
              handleModelChange={handleModelChange}
              setCurrentMessage={setCurrentMessage}
              setResetsTranscript={setResetTranscripts}
              __ask={__ask}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default QueryBox;
