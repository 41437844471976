import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import DeleteModal from '../../../../sharedComponents/DeleteModal';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../data/api/Api';
import { useAppDispatch } from '../../../../../reduxStore/redux-hooks';
import { setRefreshTitleFlag } from '../../../../../reduxStore/slices/historySlice';
import { SectionDivider } from '../../../../staticComponents/StaticHtmlGenerator';

interface EditHistoryDataProps {
  conversationHeading: string;
  conversationID: string;
}

const EditHistoryData: React.FC<EditHistoryDataProps> = ({ conversationHeading, conversationID }) => {
  const { t } = useTranslation();
  const disptach = useAppDispatch();
  const [openDatasetModal, setOpenDatasetModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const modifiedTextContent = `${t('rightPanel.confirmDelete')} "${conversationHeading}"?`;

  const handleClick = () => {
    setOpenDatasetModal(true);
  };

  const closeDatasetModal = () => {
    setOpenDatasetModal(false);
  };

  const deleteDataset = async (_id: string) => {
    setIsDeleting(true);
    try {
      await Api.deleteHistory(_id);
    } catch (error) {
      console.error('Error deleting History:', error);
    } finally {
      setIsDeleting(false);
      closeDatasetModal();
      disptach(setRefreshTitleFlag());
    }
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Typography variant="h6" style={{ margin: '0', color: '#351A55', fontWeight: '700' }}>
        {conversationHeading}
      </Typography>
      <Typography sx={{ color: '#256AFF', textDecoration: 'underline' }}>{t('rightPanel.rename')}</Typography>
      <Typography sx={{ color: '#256AFF', textDecoration: 'underline' }}>{t('history.download')}</Typography>
      <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '10px' }} />
      <Button sx={{ color: 'red', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleClick()}>
        {t('history.delete')}
      </Button>

      <DeleteModal
        openDeleteModal={openDatasetModal}
        closeDeleteModal={closeDatasetModal}
        modalAPi={() => {
          deleteDataset(conversationID);
        }}
        dialogTitle={t('rightPanel.deleteChat')}
        textContent={modifiedTextContent}
      />

      {isDeleting && <CircularProgress size={20} />}
    </Box>
  );
};

export default EditHistoryData;
