import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ActiveAgent {
  activeAgentName: string;
  activeAgentId: string;
  activeAgentModelName: string;
}

interface ActivationAgent {
  activationAgentName: string;
  activationAgentId: string;
  activationAgentModelName: string;
}

interface AgentActivationProcess {
  ShowAgentActivationDialogOnChat: boolean;
  activationAgent: ActivationAgent;
}

interface AgentsState {
  activeAgent: ActiveAgent;
  agentActivationProcess: AgentActivationProcess;
}

const initialState: AgentsState = {
  activeAgent: {
    activeAgentName: '',
    activeAgentId: '',
    activeAgentModelName: '',
  },
  agentActivationProcess: {
    ShowAgentActivationDialogOnChat: false,
    activationAgent: {
      activationAgentName: '',
      activationAgentId: '',
      activationAgentModelName: '',
    },
  },
};

export const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    activeAgent: (state, action: PayloadAction<ActiveAgent>) => {
      state.activeAgent = action.payload;
    },
    agentActivationProcess: (state, action: PayloadAction<AgentActivationProcess>) => {
      state.agentActivationProcess = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { activeAgent, agentActivationProcess } = agentsSlice.actions;

export default agentsSlice.reducer;
