import React, { useContext } from 'react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import '../i18n';
import '../css/Modal.css';
import { useNavigate } from 'react-router-dom';
import { ActiveNavItemContext } from '../components/rightPanel/RightPanelContext';

export default function Disclaimer() {
  const { t } = useTranslation();
  const [agreed, setAgreed] = useState(false);
  const isDisclaimer = true; // This will need to be updated from a fixed value
  const navigate = useNavigate();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);

  function handleCheckboxChange() {
    setAgreed(!agreed);
  }

  function handleAgreeClick() {
    navigate('/WelcomePage');
    setActiveNavItem(t('welcomepage.Home'));
  }

  return (
    <div className="modal fade">
      <div className="modal-lg">
        <div className="modal-content borderStyle">
          <div className="modal-header">
            <h4 className="modal-title">
              {isDisclaimer ? t('disclaimer.disclaimerTitle') : t('disclaimer.termsTitle')}
            </h4>
          </div>
          <div className="scroll-spy">
            <div className="modal-body">
              <p>
                <strong>{isDisclaimer ? t('disclaimer.disclaimerText') : t('disclaimer.termsText')}</strong>
              </p>
              <p>{t('disclaimer.termsOfUse')}</p>
              <p>{t('disclaimer.acknowledge')}</p>
              <h5>{t('disclaimer.ageRestriction')}</h5>
              <p>{t('disclaimer.ageRestrictionText')}</p>
              <h5>{t('disclaimer.yourQueries')}</h5>
              <p>{t('disclaimer.yourQueriesText')}</p>
              <h5>{t('disclaimer.accuracyRestrictions')}</h5>
              <p>
                <Trans i18nKey="disclaimer.accuracyRestrictionsText">
                  <a href={t('disclaimer.microsoftLink')} />
                  <a href={t('disclaimer.googleLink')} />
                </Trans>
              </p>
              <p>{t('disclaimer.responsibilityText')}</p>
              <ul>
                {(
                  t('disclaimer.responsibilityBulletPoints', {
                    returnObjects: true,
                  }) as string[]
                ).map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <p>{t('disclaimer.responsibleUse')}</p>
              <p>{t('disclaimer.wiproViews')}</p>
              <h5>{t('disclaimer.privacyPolicyTitle')}</h5>
              <p>{t('disclaimer.privacyPolicyText')}</p>

              <h5>{t('disclaimer.passwordsAndSecurityTitle')}</h5>
              <p>{t('disclaimer.passwordsAndSecurityText')}</p>

              <h5>{t('disclaimer.intellectualPropertyTitle')}</h5>
              <p>{t('disclaimer.intellectualPropertyText')}</p>
              <ul>
                {(
                  t('disclaimer.intellectualPropertyBulletPoints', {
                    returnObjects: true,
                  }) as string[]
                ).map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <p>{t('disclaimer.trademarksText')}</p>

              <h5>{t('disclaimer.ownershipAndUseOfContentTitle')}</h5>
              <p>{t('disclaimer.ownershipAndUseOfContentText1')}</p>
              <p>{t('disclaimer.ownershipAndUseOfContentText2')}</p>

              <h5>{t('disclaimer.prohibitedConductTitle')}</h5>
              <p>{t('disclaimer.prohibitedConductText')}</p>
              <ul>
                {(
                  t('disclaimer.prohibitedConductList', {
                    returnObjects: true,
                  }) as string[]
                ).map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>

              <h5>{t('disclaimer.feedbackAndSubmissionsTitle')}</h5>
              <p>{t('disclaimer.feedbackAndSubmissionsText')}</p>
              <ul>
                {(
                  t('disclaimer.feedbackAndSubmissionsList', {
                    returnObjects: true,
                  }) as string[]
                ).map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <h5>{t('disclaimer.thirdPartySitesTitle')}</h5>
              <p>{t('disclaimer.thirdPartySitesText')}</p>

              <h5>{t('disclaimer.disclaimerSubTitle')}</h5>
              <p>{t('disclaimer.disclaimerText1')}</p>
              <p>{t('disclaimer.disclaimerText2')}</p>

              <h5>{t('disclaimer.limitationOfLiabilityTitle')}</h5>
              <p>{t('disclaimer.limitationOfLiabilityText')}</p>

              <h5>{t('disclaimer.indemnificationTitle')}</h5>
              <p>
                <h5>
                  {t('disclaimer.indemnificationDetails.intro')}
                  <ul>
                    {(
                      t('disclaimer.indemnificationDetails.items', {
                        returnObjects: true,
                      }) as string[]
                    ).map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </h5>
              </p>
              <h5>{t('disclaimer.survivalTitle')}</h5>
              <p>{t('disclaimer.survivalText')}</p>

              <h5>{t('disclaimer.jurisdictionTitle')}</h5>
              <p>{t('disclaimer.jurisdictionText')}</p>

              <h5>{t('disclaimer.copyrightInfringementRequirements.title')}</h5>
              <p>
                {t('disclaimer.copyrightInfringementRequirements.notice')}
                <a href="mailto: support.ai360@wipro.com">support.ai360@wipro.com </a>{' '}
              </p>
              <p>{t('disclaimer.copyrightInfringementRequirements.mustContain')}</p>
              <ul>
                {(
                  t('disclaimer.copyrightInfringementRequirements.requirements', { returnObjects: true }) as string[]
                ).map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <h5>{t('disclaimer.miscellaneousTitle')}</h5>
              {(
                t('disclaimer.miscellaneousTexts', {
                  returnObjects: true,
                }) as string[]
              ).map((text, index) => (
                <p key={index}>{text}</p>
              ))}
              <p>
                {t('disclaimer.miscellaneousQuestions')}
                <a href="mailto: support.ai360@wipro.com">support.ai360@wipro.com </a>{' '}
              </p>
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-between">
          <FormGroup>
            <FormControlLabel
              required
              control={<Checkbox onChange={handleCheckboxChange} />}
              label="I have read and agreed to the terms & conditions"
            />
          </FormGroup>
          <Button id="agreeBtn" variant="contained" onClick={handleAgreeClick} disabled={!agreed}>
            {t('disclaimer.agreeButton')}
          </Button>
        </div>
      </div>
    </div>
  );
}
