import i18next from '../../i18n';
import * as assets from '../../assets';

export class LlmModel {
  public id: string;
  name: string;
  image: string;
  tooltip?: string;

  constructor(id: string, name: string, image: string, tooltip?: string) {
    this.id = id;
    this.name = name;
    this.image = image;
    if (tooltip) this.tooltip = tooltip;
  }

  // Getter to calculate displayName based on the name property
  get displayName(): string {
    const key = 'llmModels.' + this.id;
    const displayName = i18next.t(key);

    return displayName === key ? this.name : displayName;
  }

  public static standard: LlmModel = new LlmModel('standard', 'Standard', `${assets.defaultBot}`);
}
